import { Grid } from "@mui/material";
import { BooleanInput, SimpleForm, TextInput } from "react-admin";
import CustomFieldLabel from "shared/components/fields/CustomFieldLabel";

export default function ConfigWebhookBaseForm({ handleSubmit }: any) {
  return (
    <SimpleForm onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={4}>
          <CustomFieldLabel source="active">
            <BooleanInput source="active" fullWidth />
          </CustomFieldLabel>
        </Grid>
        <Grid item xs={4}>
          <CustomFieldLabel source="topic_action">
            <TextInput source="topic_action" />
          </CustomFieldLabel>
        </Grid>
        <Grid item xs={4}>
          <CustomFieldLabel source="topic_entity">
            <TextInput source="topic_entity" />
          </CustomFieldLabel>
        </Grid>
        <Grid item xs={4}>
          <CustomFieldLabel source="url">
            <TextInput source="url" />
          </CustomFieldLabel>
        </Grid>
      </Grid>
    </SimpleForm>
  );
}
