import { Card, CardHeader } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { baseResources } from "config_infos";
import { MaintenanceChip } from "entities/maintenances/components/MaintenanceChip";
import { useCallback, useEffect, useState } from "react";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Loading,
  RaRecord,
  SortPayload,
  TextField,
  useDataProvider,
  useList,
} from "react-admin";
import CustomBooleanField from "shared/components/fields/CustomBooleanField";
import { DateFieldFr } from "shared/components/fields/DefaultFields";
import { MaintenanceTypeEnum } from "shared/enums/globalEnums";
import useFetchPaginatedResource from "shared/hooks/useFetchPaginatedResource";
import { PaginationType } from "shared/types";
import { formatDatetimeString } from "shared/utils/helpers";

const useFetchStatuses = () => {
  const fetchStatuses = useCallback(async ({ page, size }: PaginationType) => {
    const {
      data: { items, total },
    } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.STATUSES}opened`,
      params: {
        page,
        size,
      },
    });
    return { items, total };
  }, []);

  const { error, loading, items: statuses } = useFetchPaginatedResource(fetchStatuses, 100);

  const data = statuses.filter(({ network, control, sequence }: any) => !network || !control || !sequence);

  return { error, loading, data };
};

const StatusList = () => {
  const { error, loading, data } = useFetchStatuses();
  const listContext = useList({ data });

  if (loading) return <Loading />;
  if (error) return <div>Error...</div>;

  return (
    <Card sx={{ p: 2, m: 2 }}>
      <CardHeader subheader="Current Problematic Statuses" />
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          title="Statuses"
          rowClick={(id, resource, record) => {
            const { site_id } = record;
            return `/${baseResources.sites.SITES}/${site_id}/show`;
          }}
        >
          <TextField source="site.name" sortable={true} />
          <CustomBooleanField source="network" />
          <CustomBooleanField source="control" />
          <CustomBooleanField source="sequence" />
          <DateFieldFr showTime source="timestamp" label="Start Date" />
          <FunctionField
            key="Maintenance Infos"
            label="Maintenance Infos"
            render={(record: RaRecord) => <MaintenanceField record={record} />}
            sortable={false}
          />
        </Datagrid>
      </ListContextProvider>
    </Card>
  );
};

type MaintenanceType = MaintenanceTypeEnum | null;

const MaintenanceField = ({ record }: { record: RaRecord }) => {
  const dataprovider = useDataProvider();
  const [maintenance, setmaintenance] = useState<any | null>(null); // TODO: replace any with the correct type
  const [loading, setloading] = useState(true);

  const getMaintenanceInfos = useCallback(() => {
    const maintenanceTypes: MaintenanceType[] = [
      MaintenanceTypeEnum.Enedis,
      MaintenanceTypeEnum.Preventive,
      MaintenanceTypeEnum.Fortuit,
    ];

    if (!maintenance || !maintenanceTypes.includes(maintenance.type)) {
      // return <Chip variant="outlined" icon={<WarningIcon />} label="fortuit" color="error" />;
      return <MaintenanceChip color="error" label="fortuit" />;
    }

    const label = `${maintenance.type} : ${formatDatetimeString(maintenance.start)} ➡ ${formatDatetimeString(
      maintenance.end,
    )}`;

    return <MaintenanceChip color="warning" label={label} />;
  }, [maintenance]);

  const fetchMaintenance = useCallback(async () => {
    const now = new Date();
    const { data } = await dataprovider.getList(baseResources.maintenance.MAINTENANCE, {
      sort: { field: "start", order: "ASC" } as SortPayload,
      pagination: { page: 1, perPage: 1 },
      filter: {
        site_id: record.site_id,
        start__lte: now.toISOString(),
        end__gte: now.toISOString(),
      },
    });
    return data;
  }, [dataprovider, record.site_id]);

  useEffect(() => {
    setloading(true);
    fetchMaintenance()
      .then((data) => {
        setmaintenance(data.length ? data[0] : null);
        setloading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setloading(false);
      });
  }, [fetchMaintenance]);

  if (loading) return <CircularProgress />;

  return <span>{getMaintenanceInfos()}</span>;
};

export default StatusList;
