import { Box, Grid } from "@mui/material";
import { BooleanField, DateField, Labeled, ReferenceField, Show, SimpleForm, TextField } from "react-admin";
import DurationField from "shared/components/fields/DurationField";
import { baseResources } from "config_infos";

export const StatusShow = () => {
  return (
    <Show>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="id">
                    <TextField source="id" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="Site">
                    <ReferenceField source="site_id" reference={baseResources.sites.SITES} link={"show"} />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="timestamp">
                    <DateField showTime source="timestamp" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="network">
                    <BooleanField source="network" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="control">
                    <BooleanField source="control" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="measure">
                    <BooleanField source="measure" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="sequence">
                    <BooleanField source="sequence" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="power_clamping">
                    <BooleanField source="power_clamping" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="aggregator_module">
                    <BooleanField source="aggregator_module" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="duration">
                    <DurationField source="duration" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="deleted_at">
                    <DateField showTime source="deleted_at" />
                  </Labeled>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Show>
  );
};
