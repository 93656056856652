import { Box, CircularProgress, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { baseResources } from "config_infos";
import { MaintenanceChip } from "entities/maintenances/components/MaintenanceChip";
import { SiteType } from "entities/tsoTests/testsAfrrRte/types";
import { useCallback, useEffect, useState } from "react";
import { Identifier, SortPayload, useDataProvider, useRecordContext } from "react-admin";
import IEChargeIcon from "shared/components/icons/IEChargeIcon";
import { MaintenanceTypeEnum } from "shared/enums/globalEnums";
import { useFetchSiteStatuses } from "../../sites_statuses/hooks/useFetchSiteStatuses";

const SiteTable = () => {
  const record = useRecordContext();
  const { statuses } = useFetchSiteStatuses(record?.site_ids || []);

  const getRelatedStatus = (site_id: Identifier) => {
    return statuses.find((status: any) => status.site_id === site_id) || {};
  };

  if (!record || !record.sites) return null;

  return (
    <Box sx={{ width: "100%", bgcolor: "aliceBlue" }}>
      <Typography variant="h6" gutterBottom pl={2}>
        Sites
      </Typography>
      <TableContainer component={Paper} sx={{ bgcolor: "aliceBlue" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>automate ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>IECharge</TableCell>
              <TableCell>Box State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.site_ids.map((id: Identifier) => (
              <SiteRow key={id} id={id} status={getRelatedStatus(id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

type MaintenanceType = MaintenanceTypeEnum | null;
type SiteRowProps = { id: Identifier; status: { [key: string]: any } };

const SiteRow = ({ id, status }: SiteRowProps) => {
  const dataprovider = useDataProvider();
  const [site, setSite] = useState<SiteType | null>(null);
  const [maintenanceType, setMaintenanceType] = useState<MaintenanceType>(null);
  const [loading, setloading] = useState(true);

  const fetchSite = useCallback(async () => {
    const { data } = await dataprovider.getOne(baseResources.sites.SITES, {
      id,
    });
    return data;
  }, [dataprovider, id]);

  const fetchMaintenance = useCallback(async () => {
    const now = new Date();
    const { data } = await dataprovider.getList(baseResources.maintenance.MAINTENANCE, {
      sort: { field: "start", order: "ASC" } as SortPayload,
      pagination: { page: 1, perPage: 1 },
      filter: {
        site_id: id,
        start__lte: now.toISOString(),
        end__gte: now.toISOString(),
      },
    });
    return data;
  }, [dataprovider, id]);

  const getBoxState = useCallback(
    (newMaintenanceType: MaintenanceType) => {
      const isStatusEmpty =
        Object.keys(status).length === 0 ||
        !status.network ||
        !status.control ||
        !status.measure ||
        !status.sequence ||
        !status.power_clamping;

      if (isStatusEmpty) {
        return <MaintenanceChip label="default" color="error" />;
      }

      const maintenanceTypes: MaintenanceType[] = [
        MaintenanceTypeEnum.Enedis,
        MaintenanceTypeEnum.Preventive,
        MaintenanceTypeEnum.Fortuit,
      ];

      if (maintenanceTypes.includes(newMaintenanceType)) {
        return <MaintenanceChip label={newMaintenanceType || undefined} color="warning" />;
      }

      return <MaintenanceChip />;
    },
    [status],
  );

  useEffect(() => {
    setloading(true);
    Promise.allSettled([fetchSite(), fetchMaintenance()])
      .then(([siteResult, maintenanceResult]) => {
        if (siteResult.status === "fulfilled") {
          setSite(siteResult.value);
        }
        if (maintenanceResult.status === "fulfilled" && maintenanceResult.value.length > 0) {
          setMaintenanceType(maintenanceResult.value[0]?.type);
        }
        setloading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setloading(false);
      });
  }, [fetchSite, fetchMaintenance]);

  if (loading)
    return (
      <TableRow>
        <TableCell>
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  if (!site) return null;
  return (
    <TableRow key={site.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{site.id}</TableCell>
      <TableCell>{site.name}</TableCell>
      <TableCell>{site?.iecharge && <IEChargeIcon />}</TableCell>
      <TableCell>{getBoxState(maintenanceType)}</TableCell>
    </TableRow>
  );
};

export default SiteTable;
