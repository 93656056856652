import { DateField, ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";
import FormContainer from "shared/components/forms/FormContainer";
import CustomLabeledField from "shared/components/fields/CustomLabeledField";

export const MaintenancesShow = () => (
  <Show>
    <SimpleShowLayout>
      <FormContainer>
        <CustomLabeledField source="id" FieldComponent={TextField} />
        <CustomLabeledField source="type" FieldComponent={TextField} />
        <CustomLabeledField source="start" FieldComponent={DateField} />
        <CustomLabeledField source="end" FieldComponent={DateField} />
        <CustomLabeledField source="site_id" FieldComponent={ReferenceField} />
        <CustomLabeledField source="operator" FieldComponent={TextField} />
        <CustomLabeledField source="comment" FieldComponent={TextField} />
        <CustomLabeledField source="updated_at" FieldComponent={DateField} />
        <CustomLabeledField source="created_at" FieldComponent={DateField} />
      </FormContainer>
    </SimpleShowLayout>
  </Show>
);
