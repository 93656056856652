import { Stack, Typography } from "@mui/material";
import ColorTag from "shared/components/tags/ColorTag";
import { RaRecord, useRecordContext } from "react-admin";

type GenericStatusFieldProps = {
  source: string;
  tagInfoHandler: (record: RaRecord) => { color: string; text: string };
  sortable?: boolean;
};

const GenericStatusField = ({ source, tagInfoHandler, sortable = false }: GenericStatusFieldProps) => {
  const record = useRecordContext({ source, sortable });

  const tagInfos = record ? tagInfoHandler(record) : { color: "", text: "" };

  return record ? (
    <Stack direction={"row"} spacing={1}>
      <ColorTag color={tagInfos.color} />
      <Typography variant="body2" noWrap>
        {tagInfos.text}
      </Typography>
    </Stack>
  ) : null;
};

export default GenericStatusField;
