import { Button } from "@mui/material";
import { Identifier } from "react-admin";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useExportButton } from "./hooks/useExportButton";

type CustomExportButtonProps = {
  headers?: string[];
  includeDeleted?: boolean;
  filename?: string;
  filter?: { [key: Identifier]: any };
  disabled?: boolean;
};

const CustomExportButton = ({ disabled = false, ...props }: CustomExportButtonProps) => {
  const { handleClick } = useExportButton(props);
  return (
    <Button
      variant="contained"
      onClick={handleClick}
      startIcon={<FileDownloadIcon />}
      size="small"
      disabled={disabled}
      sx={{
        minWidth: 230,
      }}
    >
      Export de la sélection
    </Button>
  );
};

export default CustomExportButton;
