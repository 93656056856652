import { Box, Divider, Grid, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  Loading,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreate,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { baseResources, SERVICES_CONFIG_INFOS } from "config_infos";
import { getSelectOptions } from "shared/utils";
import { SCHEDULER_TYPE, TASK_PERIOD } from "shared/enums";
import CustomAlert from "shared/components/statuses/CustomAlert";
import FormContainer from "shared/components/forms/FormContainer";

// const FormContainer = ({ children }: any) => {
//   return (
//     <Box
//       width={"100%"}
//       display={"grid"}
//       gridTemplateColumns={"repeat(3, 33%)"}
//       gap={2}
//       pr={3}
//     >
//       {children}
//     </Box>
//   );
// };

export default function PeriodicTaskCreateForm() {
  const [create, { isLoading, error }]: any = useCreate();
  const { service }: any = useParams();
  const navigate = useNavigate();
  const resource = baseResources[service].PERIODIC_TASKS;
  const tasksEmum: any = useMemo(
    () => SERVICES_CONFIG_INFOS.find((infos: any) => infos.param === service),
    [service],
  )?.taskEnum;

  const schedulerTypeOptions = useMemo(() => getSelectOptions(SCHEDULER_TYPE), []);
  const tasksOptions = useMemo(() => getSelectOptions(tasksEmum), [tasksEmum]);
  const tasksPeriodOptions = useMemo(() => getSelectOptions(TASK_PERIOD), []);

  const onSubmit = useCallback(
    (values: any) => {
      const { args, kwargs, start_time, expires } = values;
      const data = {
        ...values,
        args: args.map((item: any) => item.arg),
        kwargs: kwargs.reduce((acc: object, { key, value }: any) => {
          if (!key) return acc;
          return {
            ...acc,
            [key]: value,
          };
        }, {}),
      };
      if (!start_time) delete data.start_time;
      if (!expires) delete data.expires;
      create(
        resource,
        { data },
        {
          onSuccess: () => {
            navigate(`/config/${service}`);
          },
        },
      );
    },
    [create, navigate, resource, service],
  );

  if (isLoading) return <Loading />;

  return (
    <SimpleForm onSubmit={onSubmit}>
      {error && (
        <Box width={"100%"}>
          <CustomAlert
            title={<strong>{error.message}</strong>}
            subTitle={error?.body?.detail?.map((err: any, idx: number) => (
              <Typography key={idx}>{JSON.stringify(err)}</Typography>
            ))}
          />
        </Box>
      )}
      <FormContainer>
        <Box display={"flex"} flexWrap="nowrap" justifyContent={"space-around"} alignItems="center">
          <BooleanInput source="enabled" defaultValue={true} />
          <BooleanInput source="one_off" defaultValue={false} />
        </Box>
        <SelectInput
          source="scheduler_type"
          choices={schedulerTypeOptions}
          validate={required()}
          defaultValue={SCHEDULER_TYPE[0]}
        />
        <TextInput source="name" validate={required()} />
        <SelectInput source="task" choices={tasksOptions} validate={required()} defaultValue={tasksEmum[0]} />
        <DateTimeInput source="start_time" />
        <DateTimeInput source="expires" />
      </FormContainer>
      <TextInput source="description" fullWidth multiline sx={{ mt: 2 }} />
      <Divider component={Box} width={"100%"} textAlign="left">
        INTERVAL INFOS
      </Divider>
      <FormContainer>
        <NumberInput source="interval_infos.every" label="Every" defaultValue={1} inputProps={{ min: 0 }} />
        <SelectInput
          source="interval_infos.period"
          choices={tasksPeriodOptions}
          label={"Period"}
          defaultValue={TASK_PERIOD[0]}
        />
      </FormContainer>
      <Divider component={Box} width={"100%"} textAlign="left">
        CRONTAB INFOS
      </Divider>
      <FormContainer>
        <NumberInput source="crontab_infos.minute" label="Minute" defaultValue={0} inputProps={{ min: 0 }} />
        <NumberInput source="crontab_infos.hour" label="Hour" defaultValue={0} inputProps={{ min: 0 }} />
        <NumberInput source="crontab_infos.day_of_week" label="Day of week" defaultValue={0} inputProps={{ min: 0 }} />
        <NumberInput
          source="crontab_infos.day_of_month"
          label="Day of month"
          defaultValue={0}
          inputProps={{ min: 0 }}
        />
        <NumberInput
          source="crontab_infos.month_of_year"
          label="Month of year"
          defaultValue={0}
          inputProps={{ min: 0 }}
        />
      </FormContainer>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs={4}>
          <Divider textAlign="left">ARGS</Divider>
          <ArrayInput source="args">
            <SimpleFormIterator inline fullWidth>
              <TextInput source="arg" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={8}>
          <Divider textAlign="left">KWARGS</Divider>
          <ArrayInput source="kwargs">
            <SimpleFormIterator inline fullWidth>
              <TextInput source="key" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </SimpleForm>
  );
}
