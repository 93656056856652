import { Typography } from "@mui/material";
import { baseResources } from "config_infos";
import { Loading, SortPayload, useGetList, useRecordContext } from "react-admin";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export default function GroupMapContainer() {
  const height = "calc(100vh - 200px)";
  const record = useRecordContext<{ site_ids: number[] }>();
  const { site_ids } = record || { site_ids: [] };
  const { data: sites, isLoading } = useGetList(baseResources.sites.SITES, {
    sort: { field: "id", order: "ASC" } as SortPayload,
    pagination: { page: 1, perPage: 100 },
    filter: {
      id__in: site_ids,
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!sites || !sites.length) {
    return (
      <Typography variant="body1" component="p">
        No sites found
      </Typography>
    );
  }
  const coordinatesCenterFrance = { latitude: 46.2276, longitude: 2.2137 };
  const center = sites.find((site: any) => site.latitude && site.longitude) || coordinatesCenterFrance;
  return (
    <div className="leaflet-container" style={{ height: height }}>
      <MapContainer
        center={{
          lat: center.latitude,
          lng: center.longitude,
        }}
        zoom={6}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {sites.map((site: any) => {
          if (!site.latitude || !site.longitude) return null;
          return (
            <Marker
              key={site.id}
              position={{
                lat: site.latitude,
                lng: site.longitude,
              }}
            >
              <Popup>{site.city}</Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
}
