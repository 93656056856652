import { Alert, Card, CardContent, CardHeader } from "@mui/material";
import ExplicitValuesBoolenInput from "shared/components/inputs/ExplicitValuesBooleanInput";
import { baseResources } from "config_infos";
import { useCallback, useState } from "react";
import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  Identifier,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  required,
  useCreate,
  useDataProvider,
  useUpdate,
} from "react-admin";
import { useNavigate } from "react-router-dom";

export default function FakePasCreate() {
  const dataprovider = useDataProvider();
  const [create]: any = useCreate();
  const [update]: any = useUpdate();
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  const fetchGroupCode = useCallback(
    async (id: Identifier) => {
      const { data } = await dataprovider.getOne(baseResources.sites.GROUPS, {
        id,
      });
      return data;
    },
    [dataprovider],
  );

  const cardContentStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: 2,
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      setError("");
      const edr = await fetchGroupCode(values.edr);
      if (!edr) {
        setError("There was an error fetching the group code");
        return;
      }
      const edr_code = edr?.code_tm;

      update(baseResources.sites.GROUPS, {
        id: values.edr,
        data: { mode: values.test_mode ? "test" : "prod" },
      });

      create(
        baseResources.iec.FAKE_PAS,
        {
          data: {
            edr_code,
            r1_h_first_value: values.r1_h_first_value || 0,
            r1_h_second_value: values.r1_h_second_value || 0,
            r1_h_timestamp: values.r1_h_timestamp || new Date(),
            r2_h_first_value: values.r2_h_first_value || 0,
            r2_h_second_value: values.r2_h_second_value || 0,
            r2_h_timestamp: values.r2_h_timestamp || new Date(),
          },
        },
        {
          onSuccess: () => {
            navigate(baseResources.iec.FAKE_PAS);
          },
        },
      );
    },
    [create, update, navigate, fetchGroupCode],
  );

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          source="edr"
          reference={baseResources.sites.GROUPS}
          filter={{
            type: "rte_edr",
          }}
          perPage={100}
        >
          <AutocompleteInput
            validate={[required()]}
            optionText={({ id, name, code_tm }: any) => {
              return `${id}. ${name} - Code TM : ${code_tm}`;
            }}
            fullWidth
          />
        </ReferenceInput>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <ExplicitValuesBoolenInput
          label="Passer l'EDR en mode Test"
          falseLabel="Production"
          trueLabel="Test"
          source="test_mode"
          defaultValue={true}
        />

        <Card sx={{ mb: 2 }}>
          <CardHeader title="R1" />
          <CardContent sx={cardContentStyle}>
            <NumberInput source="r1_h_first_value" />
            <NumberInput source="r1_h_second_value" />
            <DateTimeInput source="r1_h_timestamp" />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="R2" />
          <CardContent sx={cardContentStyle}>
            <NumberInput source="r2_h_first_value" />
            <NumberInput source="r2_h_second_value" />
            <DateTimeInput source="r2_h_timestamp" />
          </CardContent>
        </Card>
      </SimpleForm>
    </Create>
  );
}
