import CustomBooleanField from "shared/components/fields/CustomBooleanField";
import {
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  List,
  NumberField,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";

// Finland time zone and French locale options
const dateOptions: Intl.DateTimeFormatOptions = {
  timeZone: "Europe/Helsinki",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const gateFilters = [
  <TextInput key="id_in" label="ID" source="id__in" />,
  <TextInput key="message_id" label="Message ID" source="message_id" />,
  <DateInput key="delivery_date" label="Delivery Date" source="delivery_date" />,
  <DateInput key="delivery_date_gte" label="Delivery Date GTE" source="delivery_date__gte" />,
  <DateInput key="delivery_date_lte" label="Delivery Date LTE" source="delivery_date__lte" />,
];

const GateList = () => {
  return (
    <List filters={gateFilters}>
      <Datagrid
        expand={<ExpandedBids />}
        expandSingle
        bulkActionButtons={false}
        rowClick={"expand"}
        sx={{
          "& .RaDatagrid-expandedPanel": {
            backgroundColor: "gainsboro",
          },
        }}
      >
        <TextField source="id" />
        <DateField
          source="delivery_date"
          locales={"fr-FR"}
          options={dateOptions}
          label={"Delivery date (Finnish Time)"}
        />
        <CustomBooleanField source="accepted" />
        <DateField source="created_at" locales={"fr-FR"} showTime />
        <TextField source="document_id" />
        <TextField source="reason_text" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const ExpandedBids = () => {
  const record = useRecordContext<{ bids: any[] }>();
  const { bids } = record || {};

  return (
    <Datagrid
      bulkActionButtons={false}
      data={bids}
      sx={{
        "& .RaDatagrid-headerCell": {
          backgroundColor: "gainsboro",
        },
      }}
    >
      <TextField source="id" />
      <DateField source="start_date" locales={"fr-FR"} options={dateOptions} showTime />
      <DateField source="end_date" locales={"fr-FR"} options={dateOptions} showTime />
      <NumberField source="offered_quantity_mw" />
      <NumberField source="accepted_quantity_mw" />
      <NumberField source="offered_price_eur" />
      <NumberField source="accepted_price_eur" />
      <TextField source="direction" />
      <TextField source="business_type" />
      <TextField source="status" />
    </Datagrid>
  );
};

export default GateList;
