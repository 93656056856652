import {
  ArrayField,
  Datagrid,
  DateField,
  Identifier,
  Labeled,
  Loading,
  NumberField,
  RaRecord,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";

import { Alert, Box, Divider, Stack, Typography } from "@mui/material";

import { baseResources } from "config_infos";

import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-big-scheduler-stch/lib/css/style.css";
import useStatusAware from "shared/hooks/useStatusAware";
type Config = {
  id: Identifier;
  [key: string]: any;
};

const SiteConfigurations = () => {
  const record = useRecordContext<RaRecord<Identifier>>();
  const id = record?.id;
  const { loading, setLoading } = useStatusAware();
  const [desiredConfig, setDesiredConfig] = useState<Config | null>(null);
  const [reportedConfig, setReportedConfig] = useState<Config | null>(null);

  const fetchConfigs = useCallback(async (site_id: Identifier) => {
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.SITES}/${site_id}/configurations`,
    });
    return data;
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchConfigs(id)
        .then((data) => {
          setDesiredConfig(data.desired_configuration);
          setReportedConfig(data.reported_configuration);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [fetchConfigs, id, setLoading]);

  const synchronizedConfigs = desiredConfig?.id === reportedConfig?.id;

  const alertInfos = useMemo(() => {
    const infos: CustomAlertProps = synchronizedConfigs
      ? { severity: "success", message: "SYNCHRONIZED" }
      : { severity: "warning", message: "PENDING SYNCHRONIZATION" };
    return infos;
  }, [synchronizedConfigs]);

  if (loading) return <Loading />;

  return (
    <Box>
      <CustomAlert {...alertInfos} />
      <Box>
        <Typography variant="h6" gutterBottom>
          Reported Configuration
        </Typography>
        <Configuration record={reportedConfig} />
        {!synchronizedConfigs && (
          <>
            <Typography variant="h6" gutterBottom>
              Desired Configuration
            </Typography>
            <Configuration record={desiredConfig} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default SiteConfigurations;

type CustomAlertProps = {
  severity: "error" | "info" | "success" | "warning";
  message: string;
};

const CustomAlert = ({ severity, message }: CustomAlertProps) => (
  <Alert
    variant="filled"
    severity={severity}
    sx={{
      width: "100%",
      mt: 2,
      mb: 2,
    }}
  >
    Configuration Synchronization Status : {message}
  </Alert>
);

type ConfigurationProps = {
  record?: RaRecord | null;
};

const Configuration = ({ record }: ConfigurationProps) => {
  return (
    <Box
      sx={{
        border: "1px solid gainsboro",
        borderRadius: "5px",
        pt: 2,
        pb: 2,
        mt: 2,
        mb: 2,
      }}
    >
      {record ? (
        <SimpleShowLayout record={record}>
          {/* Basic Fields */}
          <Stack direction="row" spacing={6}>
            <Labeled>
              <TextField source="id" />
            </Labeled>
            <Labeled>
              <NumberField source="efficiency_roundtrip" />
            </Labeled>
            <Labeled>
              <NumberField source="power_mw" label="Power (MW)" />
            </Labeled>
            <Labeled>
              <TextField source="rte_fcr_computation_version" />
            </Labeled>
          </Stack>

          {record?.performance_chronicle && (
            <>
              {/* Performance Chronicle */}
              <Divider />
              <Stack direction="row" spacing={2}>
                <Labeled>
                  <TextField source="performance_chronicle.type" />
                </Labeled>
                <Labeled>
                  <TextField source="performance_chronicle.chronicle_filename" />
                </Labeled>
                <Labeled>
                  <TextField source="performance_chronicle.chronicle_result" />
                </Labeled>
                <Labeled>
                  <TextField source="performance_chronicle.comment" />
                </Labeled>
                <Labeled>
                  <DateField showTime locales={"fr-FR"} source="performance_chronicle.start_date" />
                </Labeled>
                <Labeled>
                  <DateField showTime locales={"fr-FR"} source="performance_chronicle.end_date" />
                </Labeled>
                <Labeled>
                  <DateField showTime locales={"fr-FR"} source="performance_chronicle.validated_at" />
                </Labeled>
              </Stack>
              <Divider />
              {record?.performance_chronicle?.performance_chronicle_results && (
                <ArrayField source="performance_chronicle.performance_chronicle_results">
                  <Datagrid bulkActionButtons={false}>
                    <NumberField source="power_mw" label="Power (MW)" />
                    <NumberField source="soc_min_pct" label="SOC Min (%)" />
                    <NumberField source="soc_max_pct" label="SOC Max (%)" />
                    <NumberField source="soc_clipping_max_pct" label="SOC Clipping Max (%)" />
                    <NumberField source="soc_clipping_min_pct" label="SOC Clipping Min (%)" />
                    <NumberField source="autonomy_max_cmin_mwh" label="Autonomy Max Cmin (MWh)" />
                    <NumberField source="autonomy_cmax_min_mwh" label="Autonomy Cmax Min (MWh)" />
                    <NumberField source="autonomy_clipping_injection_mwh" label="Autonomy Clipping Injection (MWh)" />
                    <NumberField source="autonomy_max_min_injection_mwh" label="Autonomy Max Min Injection (MWh)" />
                    <NumberField source="autonomy_max_min_withdrawal_mwh" label="Autonomy Max Min Withdrawal (MWh)" />
                    <NumberField source="autonomy_clipping_withdrawal_mwh" label="Autonomy Clipping Withdrawal (MWh)" />
                  </Datagrid>
                </ArrayField>
              )}
            </>
          )}
        </SimpleShowLayout>
      ) : (
        <Typography variant="body1" fontStyle={"italic"} align="center" sx={{ pt: 6, pb: 6 }}>
          No reported configuration
        </Typography>
      )}
    </Box>
  );
};
