import { resolveBrowserLocale } from "react-admin";

import polyglotI18nProvider from "ra-i18n-polyglot";
import en from "./en";
import fr from "./fr";

const translations: { [key: string]: any } = { en, fr };

export const i18nProvider = polyglotI18nProvider(
  (locale) => (translations[locale] ? translations[locale] : translations.en),
  resolveBrowserLocale(),
  [
    { locale: "en", name: "English" },
    { locale: "fr", name: "Français" },
  ],
  {
    allowMissing: true,
  },
);
