import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { Chip } from "@mui/material";
import React, { ReactNode } from "react";
import { MaintenanceTypeEnum } from "shared/enums/globalEnums";

type ChipColor = "error" | "warning" | "success";

interface MaintenanceChipProps {
  label?: MaintenanceTypeEnum | string;
  color?: ChipColor;
}

export const MaintenanceChip = ({ label = "operational", color = "success" }: MaintenanceChipProps) => {
  const getIcon = (chipColor: ChipColor): ReactNode => {
    switch (chipColor) {
      case "error":
        return <CloseIcon />;
      case "warning":
        return <SettingsIcon />;
      case "success":
        return <CheckIcon />;
      default:
        return <CheckIcon />;
    }
  };

  return <Chip variant="outlined" icon={getIcon(color) as React.ReactElement} label={label} color={color} />;
};
