import { addRefreshAuthToDataProvider, Admin, AppBar, CustomRoutes, Layout, Resource } from "react-admin";

import { BrowserRouter, Route } from "react-router-dom";

import { GroupCreate } from "entities/site_service/groups/GroupCreate";
import { GroupEdit } from "entities/site_service/groups/GroupEdit";
import { GroupShow } from "entities/site_service/groups/GroupShow";
import { GroupList } from "entities/site_service/groups/list/GroupList";

import { PACreate } from "entities/topase_service/pa/PACreate";
import { PAEdit } from "entities/topase_service/pa/PAEdit";
import { PAList } from "entities/topase_service/pa/PAList";
import { PAShow } from "entities/topase_service/pa/PAShow";

import { RTE_GateCreate } from "entities/topase_service/rte_gate/RTE_GateCreate";
import { RTE_GateEdit } from "entities/topase_service/rte_gate/RTE_GateEdit";
import { RTE_GateList } from "entities/topase_service/rte_gate/RTE_GateList";
import { RTE_GateShow } from "entities/topase_service/rte_gate/RTE_GateShow";

import { RTE_Gate_StatusCreate } from "entities/topase_service/rte_gate_status/RTE_Gate_StatusCreate";
import { RTE_Gate_StatusEdit } from "entities/topase_service/rte_gate_status/RTE_Gate_StatusEdit";
import { RTE_Gate_StatusList } from "entities/topase_service/rte_gate_status/RTE_Gate_StatusList";
import { RTE_Gate_StatusShow } from "entities/topase_service/rte_gate_status/RTE_Gate_StatusShow";

import { UserCreate } from "entities/users/UserCreate";
import { UserEdit } from "entities/users/UserEdit";
import { UserShow } from "entities/users/UserShow";
import { UsersList } from "entities/users/UsersList";

import { StatusEdit } from "entities/site_service/sites_statuses/StatusEdit";
import { StatusShow } from "entities/site_service/sites_statuses/StatusShow";

import BidsCreate from "entities/regelleistung_service/bids/BidsCreate";
import BidsEdit from "entities/regelleistung_service/bids/BidsEdit";
import { BidsList } from "entities/regelleistung_service/bids/BidsList";
import BidsShow from "entities/regelleistung_service/bids/BidsShow";

import ManualBidsCreate from "entities/regelleistung_service/bids/ManualBidsCreate";

import PlanningMaskCreate from "entities/planning_service/plannings_masks/PlanningMaskCreate";

import { IecHealthCheck } from "entities/healthChecks/iec/IecHealthCheck";

import { AppLocationContext } from "@react-admin/ra-navigation";
import { getPermissionsFromAccount, loginRequest, MSALObj, tokenRequest } from "app/providers/auth/authConfig";
import combinedDataProvider from "app/providers/data/compositeDataProvider";
import { i18nProvider } from "app/providers/i18n/i18nProvider";
import ReTheme from "app/providers/theme";
import { baseResources } from "config_infos";
import { parseISO } from "date-fns";
import AfrrEngagementVisualization from "entities/affr_engagement_service/AfrrEngagementVisualization";
import { AfrrEngagementBidTimeserieShow } from "entities/affr_engagement_service/bidTimeSeries/AfrrEngagementBidTimeserieShow";
import { AfrrEngagementBidTimeSeriesList } from "entities/affr_engagement_service/bidTimeSeries/AfrrEngagementBidTimeSeriesList";
import AfrrEngagementPointList from "entities/affr_engagement_service/points/AfrrEngagementPointList";
import ConfigContainer from "entities/config/ConfigContainer";
import { ConfigTabContent } from "entities/config/ConfigTabContent";
import ConfigPeriodicTasksShow from "entities/config/periodicTasks/ConfigPeriodicTasksShow";
import PeriodicTaskCreateForm from "entities/config/periodicTasks/PeriodicTaskCreateForm";
import ConfigWebhookCreateForm from "entities/config/webhooks/ConfigWebhookCreateForm";
import ConfigWebhookShow from "entities/config/webhooks/ConfigWebhookShow";
import EcpGateCreate from "entities/ecp/gates/EcpGateCreate";
import EcpGateEdit from "entities/ecp/gates/EcpGateEdit";
import EcpGateList from "entities/ecp/gates/EcpGateList";
import EcpGateShow from "entities/ecp/gates/EcpGateShow";
import { HealthChecksContainer } from "entities/healthChecks/HealthChecksContainer";
import { HealthChecksTabContent } from "entities/healthChecks/HealthChecksTabContent";
import { MaintenanceCreate } from "entities/maintenances/MaintenancesCreate";
import { MaintenancesEdit } from "entities/maintenances/MaintenancesEdit";
import { MaintenancesList } from "entities/maintenances/MaintenancesList";
import { MaintenancesShow } from "entities/maintenances/MaintenancesShow";
import BidSettingsCreate from "entities/optimizer/bid_settings/BidSettingsCreate";
import BidSettingsEdit from "entities/optimizer/bid_settings/BidSettingsEdit";
import BidSettingsList from "entities/optimizer/bid_settings/BidSettingsList";
import BidSettingsShow from "entities/optimizer/bid_settings/BidSettingsShow";
import CertificationsCreate from "entities/optimizer/certifications/CertificationsCreate";
import CertificationsEdit from "entities/optimizer/certifications/CertificationsEdit";
import CertificationsList from "entities/optimizer/certifications/CertificationsList";
import CertificationsShow from "entities/optimizer/certifications/CertificationsShow";
import CertificationsTestsCreate from "entities/optimizer/certifications_tests/CertificationsTestsCreate";
import CertificationsTestsEdit from "entities/optimizer/certifications_tests/CertificationsTestsEdit";
import CertificationsTestsList from "entities/optimizer/certifications_tests/CertificationsTestsList";
import CertificationsTestsShow from "entities/optimizer/certifications_tests/CertificationsTestsShow";
import GroupsCertificationsCreate from "entities/optimizer/groups_certifications/GroupsCertificationsCreate";
import GroupsCertificationsEdit from "entities/optimizer/groups_certifications/GroupsCertificationsEdit";
import GroupsCertificationsList from "entities/optimizer/groups_certifications/GroupsCertificationsList";
import GroupsCertificationsShow from "entities/optimizer/groups_certifications/GroupsCertificationsShow";
import OptiTasksList from "entities/optimizer/tasks/OptiTasksList";
import PlanningCreate from "entities/planning_service/plannings/PlanningCreate";
import PlanningEdit from "entities/planning_service/plannings/PlanningEdit";
import PlanningShow from "entities/planning_service/plannings/PlanningShow";
import PlanningsList from "entities/planning_service/plannings/PlanningsList";
import { PlanningMaskList } from "entities/planning_service/plannings_masks/PlanningMaskList";
import ResultsList from "entities/regelleistung_service/results/ResultsList";
import GenerationInitialForm from "entities/site_service/performances/dagGeneration/InitialForm";
import PerformanceList from "entities/site_service/performances/performanceValidation/PerformanceList";
import { SiteCreate } from "entities/site_service/sites/SiteCreate";
import { SiteEdit } from "entities/site_service/sites/SiteEdit";
import { SiteShow } from "entities/site_service/sites/SiteShow";
import { SiteList } from "entities/site_service/sites/SitesList";
import StatusList from "entities/site_service/sites_statuses/StatusList";
import TopNiveauBidsCreate from "entities/topNiveau/bids/TopNiveauBidsCreate";
import TopNiveauBidsEdit from "entities/topNiveau/bids/TopNiveauBidsEdit";
import TopNiveauBidsList from "entities/topNiveau/bids/TopNiveauBidsList";
import TopNiveauBidsShow from "entities/topNiveau/bids/TopNiveauBidsShow";
import TopNiveauGatesCreate from "entities/topNiveau/gates/TopNiveauGatesCreate";
import TopNiveauGatesEdit from "entities/topNiveau/gates/TopNiveauGatesEdit";
import TopNiveauGatesList from "entities/topNiveau/gates/TopNiveauGatesList";
import TopNiveauGatesShow from "entities/topNiveau/gates/TopNiveauGatesShow";
import FakePasCreate from "entities/tsoTests/fake_pas/FakePasCreate";
import { FakePasList } from "entities/tsoTests/fake_pas/FakePasList";
import NlevelLossCreate from "entities/tsoTests/NLevelLoss/NlevelLossCreate";
import { NLevelLossList } from "entities/tsoTests/NLevelLoss/NLevelLossList";
import TestsAfrrRtePlanningsContainer from "entities/tsoTests/testsAfrrRte/TestsAfrrRtePlanningsContainer";
import TestsRtePlanningsContainer from "entities/tsoTests/testsRte/TestsRtePlanningsContainer";
import { msalAuthProvider, msalRefreshAuth } from "ra-auth-msal";
import { useEffect, useState } from "react";
import { AppMenu } from "./menu";

const CustomAppBar = () => <AppBar color="primary" />;

const CustomLayout = (props: any) => {
  return (
    <AppLocationContext>
      <Layout
        {...props}
        menu={AppMenu}
        appBar={CustomAppBar}
        sx={{
          "& .MuiDrawer-root": {
            width: 280,
          },
        }}
      />
    </AppLocationContext>
  );
};

const redirectOnCheckAuth = true;

const App = () => {
  const [isMSALInitialized, setMSALInitialized] = useState(false);
  const [authProvider, setAuthProvider] = useState<any | null>(null);
  const [dataProvider, setDataProvider] = useState<any | null>(null);

  useEffect(() => {
    MSALObj.initialize().then(() => {
      setMSALInitialized(true);

      const authProvider = msalAuthProvider({
        msalInstance: MSALObj,
        loginRequest,
        tokenRequest,
        getPermissionsFromAccount,
        redirectOnCheckAuth,
      });
      setAuthProvider(authProvider);

      const dataProvider = addRefreshAuthToDataProvider(
        combinedDataProvider,
        msalRefreshAuth({
          msalInstance: MSALObj,
          tokenRequest,
        }),
      );
      setDataProvider(dataProvider);
    });
  }, []);

  if (!isMSALInitialized || !authProvider || !dataProvider) {
    return <div>Loading...</div>;
  }

  const {
    sites,
    topase,
    regelleistung,
    maintenance,
    users,
    plannings,
    iec,
    optimizer,
    afrrEngagement,
    ecp,
    topNiveau,
  } = baseResources;

  return (
    <BrowserRouter>
      <Admin
        theme={ReTheme}
        dataProvider={dataProvider}
        authProvider={authProvider as any}
        i18nProvider={i18nProvider}
        requireAuth
        layout={CustomLayout}
        // layout={CustomSolarLayout}
      >
        {(permissions) => {
          return (
            <>
              <Resource
                name={sites.SITES}
                options={{ label: "Site" }}
                list={SiteList}
                edit={SiteEdit}
                show={SiteShow}
                create={SiteCreate}
                recordRepresentation={(record) => {
                  return `${record?.id} : ${record?.name}`;
                }}
              />
              <Resource
                name={sites.STATUSES}
                options={{ label: "Statuts des sites" }}
                list={StatusList}
                show={StatusShow}
                edit={StatusEdit}
                recordRepresentation="name"
              />
              <Resource
                name={sites.GROUPS}
                list={GroupList}
                show={GroupShow}
                edit={GroupEdit}
                create={GroupCreate}
                recordRepresentation={(record) => {
                  return `${record?.id} : ${record?.name} - ${record?.code}`;
                }}
              />
              <Resource name={sites.TELEMETRY_1S} recordRepresentation="timestamp" />
              <Resource name={sites.NLEVELS} recordRepresentation="timestamp" />
              <Resource
                name={sites.PERFORMANCE_CHRONICLES}
                list={PerformanceList}
                options={{ label: "Performance Chronicles" }}
              />

              <Resource name={sites.PERFORMANCE_CHRONICLES_RESULTS} />

              <Resource name={sites.CONFIGURATIONS} />

              <Resource
                name={topase.PA}
                options={{ label: "PA" }}
                list={PAList}
                edit={PAEdit}
                show={PAShow}
                create={PACreate}
                recordRepresentation="name"
              />
              <Resource
                name={topase.RTE_GATE}
                options={{ label: "RTE Gate" }}
                list={RTE_GateList}
                show={RTE_GateShow}
                edit={RTE_GateEdit}
                create={RTE_GateCreate}
                recordRepresentation="name"
              />
              <Resource
                name={topase.RTE_GATE_STATUS}
                options={{ label: "RTE Gate Status" }}
                list={RTE_Gate_StatusList}
                show={RTE_Gate_StatusShow}
                edit={RTE_Gate_StatusEdit}
                create={RTE_Gate_StatusCreate}
                recordRepresentation="name"
              />
              <Resource
                name={regelleistung.BIDS}
                options={{ label: "Bids" }}
                list={BidsList}
                show={BidsShow}
                create={BidsCreate}
              />
              <Resource name={regelleistung.BIDS_BATCH} />
              <Resource
                name={regelleistung.MANUAL_BIDS}
                options={{ label: "Manual Bids" }}
                list={BidsList}
                show={BidsShow}
                edit={BidsEdit}
                create={ManualBidsCreate}
              />
              <Resource name={regelleistung.RESULTS} options={{ label: "Results" }} list={ResultsList} />
              <Resource
                name={plannings.PLANNINGS}
                options={{ label: "Plannings" }}
                list={PlanningsList}
                show={PlanningShow}
                edit={PlanningEdit}
                create={PlanningCreate}
                recordRepresentation={(record) => {
                  return `site ${record?.site_id} : ${parseISO(record?.start_date)}`;
                }}
              />
              <Resource
                name={plannings.PLANNING_MASKS}
                options={{ label: "Planning Masks" }}
                list={PlanningMaskList}
                create={PlanningMaskCreate}
              />
              <Resource
                name={maintenance.MAINTENANCE}
                options={{ label: "Maintenances" }}
                list={MaintenancesList}
                show={MaintenancesShow}
                edit={MaintenancesEdit}
                create={MaintenanceCreate}
                recordRepresentation="name"
              />
              <Resource
                name={optimizer.BID_SETTINGS}
                list={BidSettingsList}
                show={BidSettingsShow}
                edit={BidSettingsEdit}
                create={BidSettingsCreate}
              />
              <Resource
                name={optimizer.CERTIFICATIONS_TESTS}
                list={CertificationsTestsList}
                show={CertificationsTestsShow}
                edit={CertificationsTestsEdit}
                create={CertificationsTestsCreate}
              />
              <Resource
                name={optimizer.CERTIFICATIONS}
                list={CertificationsList}
                show={CertificationsShow}
                edit={CertificationsEdit}
                create={CertificationsCreate}
                recordRepresentation="certif_name"
              />
              <Resource
                name={optimizer.GROUPS_CERTIFICATIONS}
                list={GroupsCertificationsList}
                show={GroupsCertificationsShow}
                edit={GroupsCertificationsEdit}
                create={GroupsCertificationsCreate}
              />

              <Resource name={afrrEngagement.POINT} list={AfrrEngagementPointList} />

              <Resource
                name={afrrEngagement.BID_TIME_SERIES}
                list={AfrrEngagementBidTimeSeriesList}
                show={AfrrEngagementBidTimeserieShow}
              />

              <Resource
                name={topNiveau.BIDS}
                list={TopNiveauBidsList}
                show={TopNiveauBidsShow}
                edit={TopNiveauBidsEdit}
                create={TopNiveauBidsCreate}
                options={{ label: "Top Niveau Bids" }}
              />
              <Resource
                name={topNiveau.GATES}
                list={TopNiveauGatesList}
                show={TopNiveauGatesShow}
                edit={TopNiveauGatesEdit}
                create={TopNiveauGatesCreate}
                options={{ label: "Top Niveau Gates" }}
              />

              <Resource name={ecp.BIDS} />
              <Resource
                name={ecp.GATES}
                list={EcpGateList}
                show={EcpGateShow}
                edit={EcpGateEdit}
                create={EcpGateCreate}
                options={{ label: "ECP" }}
              />

              <Resource
                name={users.USERS}
                options={{ label: "Utilisateurs" }}
                list={UsersList}
                show={UserShow}
                edit={UserEdit}
                create={permissions === "admin" ? UserCreate : undefined}
                recordRepresentation="name"
              />
              <Resource
                name={iec.RTE_AFRR_TEST_CONFIG}
                options={{ label: "N level loss" }}
                list={NLevelLossList}
                create={NlevelLossCreate}
                // recordRepresentation="name"
              />

              <Resource name={iec.HEALTH_CHECK} options={{ label: "IEC Health check" }} recordRepresentation="name" />
              <Resource name={iec.GROUPS} recordRepresentation="code" />
              <Resource name={iec.FAKE_PAS} list={FakePasList} create={FakePasCreate} />
              <Resource name={regelleistung.PERIODIC_TASKS} />
              <Resource name={sites.WEBHOOKS} />
              <Resource name={sites.PERIODIC_TASKS} />
              <Resource name={plannings.WEBHOOKS} />
              <Resource name={plannings.PERIODIC_TASKS} />
              <Resource name={iec.PERIODIC_TASKS} />
              <Resource name={topase.WEBHOOKS} />
              <Resource name={maintenance.WEBHOOKS} />
              <Resource name={users.WEBHOOKS} />
              <Resource name={iec.WEBHOOKS} />

              <CustomRoutes>
                <Route path="/config" element={<ConfigContainer />}>
                  <Route path={`:service`} element={<ConfigTabContent />} />
                  <Route path=":service/webhooks/create" element={<ConfigWebhookCreateForm />} />
                  <Route path=":service/periodic_tasks/create" element={<PeriodicTaskCreateForm />} />
                  <Route path=":service/webhooks/:webhookId" element={<ConfigWebhookShow type="edit" />} />
                  <Route path=":service/periodic_tasks/:taskId" element={<ConfigPeriodicTasksShow type="edit" />} />
                  <Route path=":service/webhooks/:webhookId/show" element={<ConfigWebhookShow />} />
                  <Route path=":service/periodic_tasks/:taskId/show" element={<ConfigPeriodicTasksShow />} />
                </Route>
                <Route path="/healthchecks" element={<HealthChecksContainer />}>
                  <Route path={`:service`} element={<HealthChecksTabContent />} />
                  <Route path=":service/iec" element={<IecHealthCheck />} />
                </Route>
                <Route path="/performance-chronicles" element={<PerformanceList />} />
                <Route path="/performance-chronicles-generation" element={<GenerationInitialForm />} />
                <Route path="/tests-afrr-rte" element={<TestsAfrrRtePlanningsContainer />} />
                <Route path="/tests-fcr-rte" element={<TestsRtePlanningsContainer />} />
                <Route path="/afrr-engagement" element={<AfrrEngagementVisualization />}></Route>
                <Route path={`/${baseResources.optimizer.TASKS}`} element={<OptiTasksList />}></Route>
              </CustomRoutes>
            </>
          );
        }}
      </Admin>
    </BrowserRouter>
  );
};

export default App;
