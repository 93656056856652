import { RteComputationVersionEnum, SiteContryEnum, SiteOperatorEnum } from "shared/enums/globalEnums";

export const socRelatedFieldValidation = (value: number, source: string, allValues: any) => {
  const { soc_reserve_inf, soc_inf, soc_sup, soc_reserve_up } = allValues;
  switch (source) {
    case "soc_reserve_inf":
      if (soc_reserve_up && value >= soc_reserve_up) {
        return "Must be lower than soc_reserve_up";
      }
      break;
    case "soc_inf":
      if (soc_sup && value >= soc_sup) {
        return "Must be lower than soc_sup";
      }
      break;
    case "soc_sup":
      if (soc_inf && value <= soc_inf) {
        return "Must be higher than soc_inf";
      }
      break;
    case "soc_reserve_up":
      if (soc_reserve_inf && value <= soc_reserve_inf) {
        return "Must be higher than soc_reserve_inf";
      }
      break;

    default:
      break;
  }
  return undefined;
};

export const socValidations = (value: number, allValues: any, inputInfos: any) => {
  if (!value) {
    return undefined;
  }
  if (value <= 0 || value >= 100) {
    return "Must be between 0 and 100";
  }
  const { source } = inputInfos;
  return socRelatedFieldValidation(value, source, allValues);
};

// Define the type for the validation errors object
type ValidationErrors<T> = Partial<Record<keyof T, string>>;

export interface SiteCreate {
  id?: number;
  name: string;
  country: SiteContryEnum;
  dso?: string;
  supplier?: string;
  soc_inf?: number;
  soc_sup?: number;
  soc_reserve_inf?: number;
  soc_reserve_sup?: number;
  useful_energy_at_1mw?: number;
  rte_fcr_computation_version?: RteComputationVersionEnum;
  site_operator?: SiteOperatorEnum;
  configuration: {
    power_mw?: number;
    efficiency_roundtrip?: number;
    rte_fcr_computation_version?: RteComputationVersionEnum;
  };
  // Add other fields as necessary
}

export const siteValidations = (values: any): ValidationErrors<SiteCreate> => {
  const isRequiredMessage = "This field is required";
  const errors: any = {
    configuration: {},
  };
  // const errors: ValidationErrors<SiteCreate> = {};
  if (!values.id) {
    errors.id = isRequiredMessage;
  }
  // Validate the name field
  if (!values.name) {
    errors.name = isRequiredMessage;
  }
  // Validate the country field
  if (!values.country) {
    errors.country = isRequiredMessage;
  }
  // Validate country-specific fields (e.g., France and Finland)
  if (!values.dso) {
    errors.dso = isRequiredMessage;
  }
  if (!values.supplier) {
    errors.supplier = isRequiredMessage;
  }
  if (!values.site_operator) {
    errors.site_operator = isRequiredMessage;
  }

  // // Validate SOC values (SOC: State of Charge)
  // if (values.soc_inf !== undefined && values.soc_sup !== undefined) {
  //   if (values.soc_inf >= values.soc_sup) {
  //     errors.soc_inf = "SOC Inf must be less than SOC Sup";
  //     errors.soc_sup = "SOC Sup must be greater than SOC Inf";
  //   }
  // }

  // if (values.soc_reserve_inf !== undefined && values.soc_reserve_sup !== undefined) {
  //   if (values.soc_reserve_inf >= values.soc_reserve_sup) {
  //     errors.soc_reserve_inf = "SOC Reserve Inf must be less than SOC Reserve Sup";
  //     errors.soc_reserve_sup = "SOC Reserve Sup must be greater than SOC Reserve Inf";
  //   }
  // }

  // // Validate SOC Reserve against general SOC
  // if (values.soc_reserve_inf !== undefined && values.soc_inf !== undefined) {
  //   if (values.soc_reserve_inf < values.soc_inf) {
  //     errors.soc_reserve_inf = "SOC Reserve Inf must be greater than or equal to SOC Inf";
  //   }
  // }

  // if (values.soc_reserve_sup !== undefined && values.soc_sup !== undefined) {
  //   if (values.soc_reserve_sup > values.soc_sup) {
  //     errors.soc_reserve_sup = "SOC Reserve Sup must be less than or equal to SOC Sup";
  //   }
  // }

  // // Validate `useful_energy_at_1mw` to have a maximum of 3 decimal places
  // if (values.useful_energy_at_1mw !== undefined) {
  //   const decimalPart = (values.useful_energy_at_1mw.toString().split(".")[1] || "").length;
  //   if (decimalPart > 3) {
  //     errors.useful_energy_at_1mw = "Must not have more than three decimal places";
  //   }
  // }

  // // Example: Ensure a field like `rte_fcr_computation_version` requires other fields
  // if (values.rte_fcr_computation_version === RTEFcrComputationVersion.FROM_AUTOMATON_CONFIGURATION) {
  //   if (
  //     values.soc_inf === undefined ||
  //     values.soc_sup === undefined ||
  //     values.soc_reserve_inf === undefined ||
  //     values.soc_reserve_sup === undefined
  //   ) {
  //     errors.rte_fcr_computation_version =
  //       "SOC fields (soc_inf, soc_sup, soc_reserve_inf, soc_reserve_sup) are required for this computation version";
  //   }
  // }

  // Validate the configuration object
  if (values.configuration) {
    // Validate the power_mw field
    if (!values.configuration.power_mw) {
      errors.configuration.power_mw = isRequiredMessage;
    } else {
      // Validate the power_mw field to be a positive number
      if (values.configuration.power_mw < 0) {
        errors.configuration.power_mw = "Must be a positive number";
      }
    }
    // Validate the efficiency_roundtrip field
    if (values.configuration.efficiency_roundtrip === undefined) {
      errors.configuration.efficiency_roundtrip = isRequiredMessage;
    } else {
      // Validate the efficiency_roundtrip field to be a positive number
      if (values.configuration.efficiency_roundtrip < 0 || values.configuration.efficiency_roundtrip > 1) {
        errors.configuration.efficiency_roundtrip = "Must be a number between 0 and 1";
      }
    }
    // Validate the rte_fcr_computation_version field
    if (values.configuration.rte_fcr_computation_version === undefined) {
      errors.configuration.rte_fcr_computation_version = isRequiredMessage;
    }
  }

  // Return the errors object, if empty no validation error exists
  return errors;
};
