import AcUnitIcon from "@mui/icons-material/AcUnit";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BuildIcon from "@mui/icons-material/Build";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConstructionIcon from "@mui/icons-material/Construction";
import Crop169Icon from "@mui/icons-material/Crop169";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import GroupsIcon from "@mui/icons-material/Groups";
import LoginIcon from "@mui/icons-material/Login";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import PetsIcon from "@mui/icons-material/Pets";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SignalCellularNoSimIcon from "@mui/icons-material/SignalCellularNoSim";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import UpdateIcon from "@mui/icons-material/Update";
import WineBarIcon from "@mui/icons-material/WineBar";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Divider } from "@mui/material";
import { MultiLevelMenu, SolarMenu, SolarMenuItem, SolarMenuList } from "@react-admin/ra-navigation";
import { baseResources } from "config_infos";
import React from "react";
import { useResourceDefinitions } from "react-admin";

export const menuItems = [
  {
    top: true,
    label: baseResources.sites.SITES,
    // label: "Jbox",
    icon: <Crop169Icon />,
    listItems: [
      { label: "Sites", path: baseResources.sites.SITES, icon: <FmdGoodIcon /> },
      { label: "Groups", path: baseResources.sites.GROUPS, icon: <GroupsIcon /> },
    ],
  },
  {
    top: true,

    label: "Fleet Status",
    icon: <DeviceThermostatIcon />,
    listItems: [
      {
        label: "Maintenances",
        path: baseResources.maintenance.MAINTENANCE,
        icon: <EngineeringIcon />,
      },
      { label: "Statuses", path: baseResources.sites.STATUSES, icon: <NetworkCheckIcon /> },
    ],
  },
  {
    top: true,

    label: "Plannification",
    icon: <AccessAlarmIcon />,
    listItems: [
      {
        label: "Plannings",
        path: baseResources.plannings.PLANNINGS,
        icon: <CalendarMonthIcon />,
      },
      {
        label: "Planning Masks",
        path: baseResources.plannings.PLANNING_MASKS,
        icon: <UpdateIcon />,
      },
    ],
  },
  {
    top: true,

    label: "Performance Tests",
    icon: <ConstructionIcon />,
    listItems: [
      { label: "Validation", path: baseResources.sites.PERFORMANCE_CHRONICLES, icon: <AlignVerticalBottomIcon /> },
      { label: "Generation", path: "performance-chronicles-generation", icon: <EngineeringIcon /> },
    ],
  },

  {
    top: true,

    label: "France",
    icon: <WineBarIcon />,
    listItems: [
      {
        label: "Regelleistung",
        icon: <RequestQuoteIcon />,
        listItems: [{ label: "Bids", path: baseResources.regelleistung.BIDS, icon: <ReceiptIcon /> }],
      },
      {
        label: "RACOON",
        icon: <PetsIcon />,
        listItems: [
          {
            label: "Visualization Charts",
            path: "afrr-engagement",
            icon: <AssignmentTurnedInIcon />,
          },
          {
            label: "Bids",
            path: baseResources.afrrEngagement.POINT,
            icon: <RadioButtonCheckedIcon />,
          },
        ],
      },
      {
        label: "TOPNIVEAU",
        icon: <StarBorderIcon />,
        listItems: [
          {
            label: "Bids",
            path: baseResources.topNiveau.BIDS,
            icon: <ReceiptIcon />,
          },
        ],
      },

      {
        label: "Tests TSO",
        icon: <BuildIcon />,
        listItems: [
          { label: "IEC Fake PAs", path: baseResources.iec.FAKE_PAS, icon: <ReceiptLongIcon /> },
          {
            label: "IEC Loss N level",
            path: baseResources.iec.RTE_AFRR_TEST_CONFIG,
            icon: <SignalCellularNoSimIcon />,
          },
          { label: "Tests AFRR RTE", path: "/tests-afrr-rte", icon: <SsidChartIcon /> },
          { label: "Tests FCR RTE", path: "/tests-fcr-rte", icon: <SsidChartIcon /> },
        ],
      },
    ],
  },

  {
    top: true,

    label: "Finland",
    icon: <AcUnitIcon />,
    listItems: [
      {
        label: "ECP",
        path: baseResources.ecp.GATES,
        icon: <ReceiptIcon />,
      },
    ],
  },

  {
    top: true,

    label: "Optimizer",
    icon: <AutoFixHighIcon />,
    listItems: [
      {
        label: "Certification Tests",
        path: baseResources.optimizer.CERTIFICATIONS_TESTS,
        icon: <CheckCircleIcon />,
      },
      { label: "Certifications", path: baseResources.optimizer.CERTIFICATIONS, icon: <WorkspacePremiumIcon /> },
      {
        label: "Groups Certifications",
        path: baseResources.optimizer.GROUPS_CERTIFICATIONS,
        icon: <GroupWorkIcon />,
      },
      { label: "Opti tasks", path: baseResources.optimizer.TASKS, icon: <PsychologyIcon /> },
    ],
  },

  {
    top: true,

    label: "Authentication",
    icon: <LoginIcon />,
    listItems: [{ label: "Users", path: baseResources.users.USERS, icon: <AccountCircleIcon /> }],
  },
];
