import { Alert, Box } from "@mui/material";
import axios from "axios";
import { baseResources } from "config_infos";
import { format, subDays } from "date-fns";
import useStatusAware from "shared/hooks/useStatusAware";
import { useCallback, useEffect } from "react";
import { Loading } from "react-admin";
import ReactApexChart from "react-apexcharts";
import { RtePacketType } from "shared/types";
import { Ti2TsEnum } from "shared/types/enums";

export default function RteTsChart({ ioas }: { ioas: { [key: string]: number } }) {
  const {
    error,
    loading,
    data: tsPackets,
    setError,
    setLoading,
    setData: setTsPackets,
  } = useStatusAware(true, null, {});
  const CHART_HEIGHT = 300;
  const START_DATE = subDays(new Date(), 3);

  const fetchData = useCallback(
    async (ioa: number) => {
      const {
        data: { items, total },
      } = await axios({
        method: "get",
        url: `${process.env.REACT_APP_IEC_API_URL}/${baseResources.iec.PACKETS}`,
        params: {
          page: 1,
          size: 100,
          order_by: "timestamp",
          ioa,
          timestamp__gte: START_DATE.toISOString(),
        },
      });
      return { items, total };
    },
    [START_DATE],
  );

  useEffect(() => {
    const fetchDataIfNeeded = async () => {
      setLoading(true);
      const fetchPromises = [];

      if (ioas[Ti2TsEnum.PART_FSM]) {
        fetchPromises.push(fetchData(ioas[Ti2TsEnum.PART_FSM]));
      }

      if (ioas[Ti2TsEnum.RSFP]) {
        fetchPromises.push(fetchData(ioas[Ti2TsEnum.RSFP]));
      }

      if (fetchPromises.length === 0) {
        setLoading(false);
        return;
      }

      try {
        const results = await Promise.allSettled(fetchPromises);
        setTsPackets(() => {
          const fsmPackets = results[0]?.status === "fulfilled" ? results[0].value.items : [];
          const rsfpPackets = results[1]?.status === "fulfilled" ? results[1].value.items : [];
          const packets = {
            ...(fsmPackets.length && { [Ti2TsEnum.PART_FSM]: fsmPackets }),
            ...(rsfpPackets.length && { [Ti2TsEnum.RSFP]: rsfpPackets }),
          };
          return packets;
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataIfNeeded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ioas, setTsPackets, setError, setLoading]);

  type TsPoint = {
    x: string;
    y: [number, number];
  };

  const series = Object.entries(tsPackets).reduce(
    (series: any, [ti2, packets]: any) => {
      // remove following packets with the same value for range creation
      const alternatingPackets = packets.reduce((acc: RtePacketType[], curr: RtePacketType, index: number) => {
        if (index === 0 || acc[acc.length - 1].value !== curr.value) {
          acc.push(curr);
        }
        return acc;
      }, [] as RtePacketType[]);

      // add an extra packet to display the current ts state
      const extraPacket = { ...alternatingPackets.at(-1) };
      extraPacket.timestamp = new Date().toISOString();
      alternatingPackets.push(extraPacket);

      const trueTsPoints: TsPoint[] = [];
      const falseTsPoints: TsPoint[] = [];
      // separate packets based on value for RSFP and PART_FSM packets
      alternatingPackets.forEach((packet: RtePacketType, index: number, array: RtePacketType[]) => {
        const startDate = packet.timestamp;
        const endDate = index < array.length - 1 ? array[index + 1].timestamp : packet.timestamp;
        const point: TsPoint = {
          x: ti2,
          y: [new Date(startDate).getTime(), new Date(endDate).getTime()],
        };
        if (packet.value) {
          trueTsPoints.push(point);
        } else {
          falseTsPoints.push(point);
        }
      });

      return [
        {
          name: "TS True",
          data: [...series[0].data, ...trueTsPoints],
        },
        {
          name: "TS False",
          data: [...series[1].data, ...falseTsPoints],
        },
      ];
    },
    // initialize series with empty data
    [
      {
        name: "TS True",
        data: [],
      },
      {
        name: "TS False",
        data: [],
      },
    ],
  );

  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        barHeight: "50%",
        rangeBarGroupRows: true,
      },
    },
    xaxis: {
      type: "datetime",
      min: subDays(new Date(), 1).getTime(), // Add a day to make sure enough TS are displayed
      max: new Date().getTime(),
      labels: {
        offsetY: -10, // Increase the offset to avoid overlapping
        show: true,
        rotate: -30,
        rotateAlways: true,
        formatter: function (value: any) {
          return format(new Date(value), "HH:mm:ss");
        },
        style: {
          fontSize: "12px",
          colors: ["#333"],
        },
      },
    },
    title: {
      text: "TS Packets (last 24h)",
      align: "center",
    },
    fill: {
      type: "gradient",
      opacity: 0.6,
      colors: ["green", "red"],
      gradient: {
        shade: "yellow",
        type: "vertical",
        shadeIntensity: 0.1,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 0],
        colorStops: [],
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["green", "red"],
    tooltip: {
      x: {
        formatter: function (value: number | string) {
          if (typeof value === "number") {
            return format(new Date(value), "dd/MM/yyyy HH:mm:ss");
          }
          return value;
        },
      },
    },
  };

  if (loading)
    return (
      <Loading
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      />
    );

  if (error)
    return (
      <Alert
        severity="error"
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      >
        {error.message}
      </Alert>
    );

  return (
    <Box>
      <ReactApexChart options={options as any} series={series} type="rangeBar" height={CHART_HEIGHT} />
    </Box>
  );
}
