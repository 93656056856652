import { Alert, Box } from "@mui/material";
import axios from "axios";
import { baseResources } from "config_infos";
import { format, parseISO, startOfMinute, subMinutes } from "date-fns";
import useFetchPaginatedResource from "shared/hooks/useFetchPaginatedResource";
import { useCallback, useMemo } from "react";
import { Loading } from "react-admin";
import ReactApexChart from "react-apexcharts";
import { PaginationType, RtePacketType } from "shared/types";

const RteTmChart = ({ ioas }: { ioas: number[] }) => {
  const CHART_HEIGHT = 400;
  const TIME_WINDOW = 30;

  const getTmPacketsGeneric = useCallback(
    async ({ page, size }: PaginationType) => {
      const {
        data: { items, total },
      } = await axios({
        method: "get",
        url: `${process.env.REACT_APP_IEC_API_URL}/${baseResources.iec.PACKETS}`,
        params: {
          page,
          size,
          ioa__in: ioas.toString(),
          timestamp__gte: startOfMinute(subMinutes(new Date(), TIME_WINDOW)).toISOString(),
          order_by: "timestamp",
        },
      });
      return { items, total };
    },
    [ioas],
  );

  const { error, loading, items: tmPackets } = useFetchPaginatedResource(getTmPacketsGeneric, 100);

  const aggregatedData = useMemo(() => {
    return tmPackets.reduce(
      (
        acc: {
          [key: string]: { valid: number; invalid: number };
        },
        packet: RtePacketType,
      ) => {
        const startMinute = format(startOfMinute(parseISO(packet.timestamp)), "dd/MM/yyyy HH:mm");
        if (!acc[startMinute]) {
          acc[startMinute] = { valid: 0, invalid: 0 };
        }

        if (packet.iv) {
          acc[startMinute].invalid += 1;
        } else {
          acc[startMinute].valid += 1;
        }

        return acc;
      },
      {},
    );
  }, [tmPackets]);

  const formatChartData = useCallback((aggregatedData: any) => {
    const categories = Object.keys(aggregatedData).sort();
    const validSeries: number[] = [];
    const invalidSeries: number[] = [];
    categories.forEach((category) => {
      validSeries.push(aggregatedData[category].valid);
      invalidSeries.push(aggregatedData[category].invalid);
    });
    return {
      categories,
      series: [
        {
          name: "Valid",
          data: validSeries,
        },
        {
          name: "Invalid",
          data: invalidSeries,
        },
      ],
    };
  }, []);

  const chartData = useMemo(() => formatChartData(aggregatedData), [aggregatedData, formatChartData]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "TM Packets",
      align: "center",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: chartData.categories,

      labels: {
        offsetY: 5,
      },
    },
    yaxis: {
      title: {
        text: "Packet Number per minute",
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    annotations: {
      yaxis: [
        {
          y: 20,
          y2: 35,
          borderColor: "rgba(69, 245, 66, 1)",
          fillColor: "rgba(69, 245, 66, 0.7)",
          label: {
            borderColor: "rgba(69, 245, 66, 1)",
            style: {
              color: "black",
              background: "rgba(69, 245, 66, 1)",
            },
            text: "Normal Range",
          },
        },
      ],
    },
    colors: ["#0000FF", "#FF0000"],
  };

  if (loading)
    return (
      <Loading
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      />
    );

  if (error)
    return (
      <Alert
        severity="error"
        sx={{
          maxHeight: CHART_HEIGHT,
        }}
      >
        {error.message}
      </Alert>
    );

  return (
    <Box>
      <ReactApexChart options={options} series={chartData.series} type="line" height={CHART_HEIGHT} />
    </Box>
  );
};

export default RteTmChart;
