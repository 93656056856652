import { Avatar } from "@mui/material";
import ChargingStation from "assets/images/chargingStation.png";

type IEChargeIconProps = {
  style?: React.CSSProperties;
  avatarColor?: string;
  avatarStyle?: React.CSSProperties;
};

const IEChargeIcon = ({ style, avatarColor, avatarStyle }: IEChargeIconProps) => {
  return (
    <Avatar
      sx={{
        bgcolor: (theme) => avatarColor || theme.palette.primary.main,
        ...avatarStyle,
      }}
    >
      <img
        src={ChargingStation}
        alt="Icon"
        style={{
          width: "20px",
          height: "20px",
          transform: "translate(2px, 0px)",
          ...style,
        }}
      />
    </Avatar>
  );
};

export default IEChargeIcon;
