import { Typography } from "@mui/material";
import { BooleanField, Button, Datagrid, DateField, List, TextField, TopToolbar } from "react-admin";
import { useLocation } from "react-router-dom";
import CustomLink from "shared/components/fields/CustomLink";
import CustomListButton from "shared/components/buttons/CustomShowButton";

type Props = {
  endpoint: string;
};

export default function ConfigWebhooksList({ endpoint }: Props) {
  const { pathname } = useLocation();
  const showResource = `${pathname}/webhooks`;
  const createPath = `${showResource}/create`;
  return (
    <>
      <Typography variant="h5">Webhooks</Typography>
      <TopToolbar>
        <CustomLink to={createPath}>
          <Button label="Créer" />
        </CustomLink>
      </TopToolbar>
      <List resource={endpoint} disableSyncWithLocation>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" sortable={false} />
          <TextField source="topic_action" sortable={false} />
          <TextField source="topic_entity" sortable={false} />
          <TextField source="url" sortable={false} />
          <BooleanField source="active" sortable={false} />
          <DateField showTime source="created_at" sortable={false} />
          <DateField showTime source="updated_at" sortable={false} />
          <CustomListButton link={showResource} />
          <CustomListButton link={showResource} type={"show"} />
        </Datagrid>
      </List>
    </>
  );
}
