import { baseResources } from "config_infos";
import { enumToChoices } from "shared/utils/reactAdminHelpers";
import { useCallback, useMemo } from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TransformData,
  useNotify,
} from "react-admin";
import { OptimizerCertificationStatusEnum, OptimizerGroupTypeEnum } from "../opti_enums";
import { getBasicErrorMessage } from "shared/utils/errorHandlers";

export default function GroupsCertificationsCreate() {
  const notify = useNotify();
  const statusOptions = useMemo(() => enumToChoices(OptimizerCertificationStatusEnum), []);

  const onError = (error: Error) => {
    const message = getBasicErrorMessage(error);
    notify(`Error: ${message}`, { type: "error" });
  };

  const transform = useCallback((data: TransformData) => {
    return {
      ...data,
      group_type: OptimizerGroupTypeEnum.RTE_EDP,
    };
  }, []);

  return (
    <Create transform={transform} mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput source="name" label="Group Certification Name" validate={[required()]} fullWidth />
        <SelectInput source="status" choices={statusOptions} validate={[required()]} fullWidth />

        <ReferenceInput
          source="group_code"
          reference={baseResources.sites.GROUPS}
          filter={{
            type: OptimizerGroupTypeEnum.RTE_EDP,
          }}
        >
          <AutocompleteInput
            optionText={({ id, name, code }: any) => {
              return `${id} - ${name} - ${code}`;
            }}
            fullWidth
            validate={[required()]}
          />
        </ReferenceInput>
        <ReferenceInput source="certification_id" reference={baseResources.optimizer.CERTIFICATIONS}>
          <AutocompleteInput
            optionText={({ id, certif_name }: any) => {
              return `${id} - ${certif_name}`;
            }}
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
