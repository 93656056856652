import { ChartData } from "chart.js";
import useDateRangePicker from "shared/components/pickers/DateRangePicker/hooks/useDateRangePicker";
import { PointType } from "entities/affr_engagement_service/types";
import { baseResources } from "config_infos";
import { addDays, endOfDay, startOfDay } from "date-fns";
import useStatusAware from "shared/hooks/useStatusAware";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SortPayload, useDataProvider } from "react-admin";
import { prepareData } from "../utils";

export default function useBarChartPointsData() {
  const [points, setPoints] = useState<PointType[]>([]);
  const dataProvider = useDataProvider();
  const { error, loading: isLoading, setError, setLoading } = useStatusAware();
  const [triggerRefresh, setTriggerRefresh] = useState(1);
  const { dateRange, setDateRange } = useDateRangePicker(startOfDay(new Date()), addDays(endOfDay(new Date()), 2));

  const filter = useMemo(() => {
    return {
      start_date__gte: dateRange.startDate.toISOString(),
      end_date__lte: dateRange.endDate.toISOString(),
      order_by: "-start_date",
    };
  }, [dateRange]);

  const fetchAllRessourcesWithinTimeRange = useCallback(async (): Promise<any[]> => {
    let itemsTotal = 0;
    let itemsCount = 0;
    let currentPage = 1;
    let result: any[] = [];

    setLoading(true);
    do {
      await dataProvider
        .getList(baseResources.afrrEngagement.POINT, {
          sort: { field: "start_date", order: "ASC" } as SortPayload,
          pagination: { page: currentPage, perPage: 100 },
          filter,
        })
        .then(({ data, total }) => {
          itemsCount += data.length;
          itemsTotal = total ?? 0;
          currentPage++;
          result = result.concat(data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } while (itemsCount < itemsTotal);
    setLoading(false);
    setPoints(result);

    return result;
  }, [dataProvider, filter, setError, setLoading]);

  useEffect(() => {
    fetchAllRessourcesWithinTimeRange();
  }, [dateRange, triggerRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const parsedInfos = useMemo(() => {
    if (points) {
      return prepareData(points, dateRange);
    }
    return null;
  }, [points, dateRange]);

  const data: ChartData<"bar"> = {
    labels: (parsedInfos && parsedInfos.labels) ?? [],
    datasets: (parsedInfos && parsedInfos.datasets) ?? [],
  };

  return { data, dateRange, setDateRange, isLoading, error, setTriggerRefresh };
}
