import frenchMessages from "ra-language-french";

const customFrenchMessages = {
  ...frenchMessages,
  resources: {
    sites: {
      info: "Information sur le site",
      name: "Site",
      country: "Pays",
      dso: "Distributeur",
      valorisation_start_date: "Début de valorisation",
      groups_info: "Détails des groupes liés",
    },
    groups: {
      info: "Information sur le groupe",
      name: "Groupe",
      sites_info: "Détail des sites",
    },
  },
};

export default customFrenchMessages;
