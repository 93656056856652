import { PLANNING_TASKS, SITES_TASKS, IEC_TASKS } from "shared/enums";

interface BaseResources {
  [key: string]: {
    [key: string]: string;
  };
}
const {
  REACT_APP_SITE_API_URL,
  REACT_APP_AUTH_API_URL,
  REACT_APP_TOPAZE_API_URL,
  REACT_APP_MAINTENANCE_API_URL,
  REACT_APP_REGELLEISTUNG_API_URL,
  REACT_APP_PLANNINGS_API_URL,
  REACT_APP_IEC_API_URL,
  REACT_APP_OPTIMIZER_API_URL,
  REACT_APP_AFRR_ENGAGEMENT_API_URL,
  REACT_APP_ECP_API_URL,
  REACT_APP_AIRFLOW_SERVER,
  REACT_APP_TOP_NIVEAU_API_URL,
} = process.env;

export const baseResources: BaseResources = {
  sites: {
    ENV_VAR: REACT_APP_SITE_API_URL as string,
    SITES: "sites",
    STATUSES: "sites/sites-statuses/",
    GROUPS: "sites/groups/",
    TELEMETRY_1S: "sites/sites-telemetry/1s/",
    WEBHOOKS: "sites/config/webhooks",
    PERIODIC_TASKS: "sites/config/periodic_tasks",
    NLEVELS: "sites/n_levels/",
    PERFORMANCE_CHRONICLES: "sites/performance_chronicles/",
    PERFORMANCE_CHRONICLE_RESULTS: "sites/performance_chronicle_results/",
    CONFIGURATIONS: "sites/configurations",
  },
  topase: {
    ENV_VAR: REACT_APP_TOPAZE_API_URL as string,
    PA: "pa",
    RTE_GATE: "rte_gate",
    RTE_GATE_STATUS: "rte_gate_status",
    WEBHOOKS: "",
  },
  maintenance: {
    ENV_VAR: REACT_APP_MAINTENANCE_API_URL as string,
    MAINTENANCE: "maintenance/maintenances",
    WEBHOOKS: "maintenance/config/webhooks",
  },
  users: {
    ENV_VAR: REACT_APP_AUTH_API_URL as string,
    USERS: "users",
    WEBHOOKS: "users/config/webhooks",
  },
  regelleistung: {
    ENV_VAR: REACT_APP_REGELLEISTUNG_API_URL as string,
    BIDS: "regelleistung/bids/",
    BIDS_BATCH: "regelleistung/bids/batch/",
    MANUAL_BIDS: "regelleistung/manual_bids/",
    TENDERS: "regelleistung/tenders/",
    RESULTS: "regelleistung/results/",
    HEALTH: "regelleistung/health/",
    DEFAULT_CONFIG: "regelleistung/config/default-config/latest/",
    WEBHOOKS: "regelleistung/config/webhooks/",
    PERIODIC_TASKS: "regelleistung/config/periodic_tasks/",
  },
  plannings: {
    ENV_VAR: REACT_APP_PLANNINGS_API_URL as string,
    PLANNINGS: "plannings/",
    PLANNING_MASKS: "plannings/planning-masks/",
    WEBHOOKS: "plannings/config/webhooks",
    PERIODIC_TASKS: "plannings/config/periodic_tasks/",
  },
  iec: {
    ENV_VAR: REACT_APP_IEC_API_URL as string,
    RTE_TEST_CONFIG: "iec/rte_test_configs/",
    RTE_AFRR_TEST_CONFIG: "iec/rte_afrr_test_configs/",
    GROUPS: "iec/groups/",
    WEBHOOKS: "iec/config/webhooks",
    FAKE_PAS: "iec/fake-pas/",
    PERIODIC_TASKS: "iec/config/periodic_tasks/",
    HEALTH_CHECK: "healthcheck/",
    CONFIGS: "iec/configs/",
    PACKETS: "iec/iec104_packets/",
  },
  testsRte: {
    ENV_VAR: REACT_APP_IEC_API_URL as string,
    PLANNINGS: "testsRte/plannings",
  },
  optimizer: {
    ENV_VAR: REACT_APP_OPTIMIZER_API_URL as string,
    BID_SETTINGS: "bid_settings",
    CERTIFICATIONS: "certifications",
    CERTIFICATIONS_TESTS: "certifications_tests",
    GROUPS_CERTIFICATIONS: "groupscertifications",
    TASKS: "opti_tasks",
  },
  afrrEngagement: {
    ENV_VAR: REACT_APP_AFRR_ENGAGEMENT_API_URL as string,
    ENGAGEMENT: "engagement/",
    DOCUMENTS: "documents",
    GATE_DOCUMENT: "gate_document",
    BID_TIME_SERIES: "bidtimeseries",
    POINT: "point",
  },
  topNiveau: {
    ENV_VAR: REACT_APP_TOP_NIVEAU_API_URL as string,
    BIDS: "bids",
    GATES: "gates",
    DOCUMENTS: "documents",
  },
  ecp: {
    ENV_VAR: REACT_APP_ECP_API_URL as string,
    BIDS: "bids",
    GATES: "gate",
  },
  airflow: {
    ENV_VAR: REACT_APP_AIRFLOW_SERVER as string,
    DAGS: "api/v1/dags/chronique_anniversary_report_generation",
  },
};

export const SERVICES_CONFIG_INFOS = [
  {
    id: 0,
    name: "Sites",
    param: "sites",
    taskEnum: SITES_TASKS,
  },
  {
    id: 1,
    name: "Topase",
    param: "topase",
    taskEnum: null,
  },
  {
    id: 2,
    name: "Maintenance",
    param: "maintenance",
    taskEnum: null,
  },
  {
    id: 3,
    name: "Utilisateurs",
    param: "users",
    taskEnum: null,
  },
  {
    id: 4,
    name: "Regelleistung",
    param: "regelleistung",
    taskEnum: null,
  },
  {
    id: 5,
    name: "Plannings",
    param: "plannings",
    taskEnum: PLANNING_TASKS,
  },
  {
    id: 6,
    name: "IEC",
    param: "iec",
    taskEnum: IEC_TASKS,
  },
  {
    id: 7,
    name: "Optimizer",
    param: "optimizer",
    taskEnum: null,
  },
].map((service) => ({
  ...service,
  endpoints: {
    webhook: baseResources[service.param].WEBHOOKS,
    periodicTasks: baseResources[service.param].PERIODIC_TASKS,
  },
}));

export type configInfosPropsTypes = {
  id: number;
  param: string;
  content: any;
  endpoints: object;
};

export const HEALTHCHECKS_INFOS = [{ id: 0, name: "IEC", param: "iec" }];

export const METABASE_INFOS = [
  {
    id: 0,
    name: "JoulesEden - Operations",
    param: "operations",
    taskEnum: null,
  },
  /*   {
    id: 1,
    name: "Marches",
    param: "marches",
    taskEnum: null,
  }, */
];
