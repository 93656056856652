import { Box } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { useChartConfig } from "./hooks/useChartConfig";
import { PlanningsBarChartProps } from "../../../../shared/types";
import { Chart as ChartJS, registerables } from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(...registerables);

export function PlanningsBarChart({ plannings, masks, dateRange }: PlanningsBarChartProps) {
  const { data, options } = useChartConfig(plannings, masks, dateRange);

  return (
    <Box width={"100%"} minHeight={400}>
      <Bar options={options} data={data} />
    </Box>
  );
}
