import { Grid, Paper } from "@mui/material";
import { useCallback } from "react";
import {
  BooleanField,
  BooleanInput,
  DateField,
  DateTimeInput,
  RecordContextProvider,
  SimpleForm,
  TextField,
  Toolbar,
  useGetOne,
  useUpdate,
} from "react-admin";
import { useParams } from "react-router-dom";
import ConditionalPeriodicTypeField from "../Fields/ConditionalPeriodicTypeField";
import { baseResources } from "config_infos";
import StatusAware from "shared/components/statuses/StatusAware";
import CustomFieldLabel from "shared/components/fields/CustomFieldLabel";

type Props = {
  type?: string;
};
interface TasksParams {
  service?: any;
  taskId?: any;
}

export default function ConfigPeriodicTasksShow({ type }: Props) {
  const { service, taskId }: TasksParams = useParams();
  const resource = baseResources[service].PERIODIC_TASKS;
  const { data, isLoading, error }: any = useGetOne(resource, {
    id: taskId,
  });
  const [update, { isLoading: loadingUpdate }]: any = useUpdate();
  const handleSubmit = useCallback(
    (data: any) => {
      update(resource, {
        id: taskId,
        data,
      });
    },
    [resource, taskId, update],
  );
  const isEdit = type === "edit";
  return (
    <StatusAware error={error} loading={isLoading}>
      <RecordContextProvider value={data}>
        <SimpleForm onSubmit={handleSubmit} toolbar={isEdit ? <Toolbar /> : <></>}>
          <Grid container spacing={2} component={Paper} elevation={2} mt={2} p={2}>
            <Grid item xs={4}>
              <CustomFieldLabel source="id">
                <TextField source="id" />
              </CustomFieldLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomFieldLabel source="name">
                <TextField source="name" />
              </CustomFieldLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomFieldLabel source="task">
                <TextField source="task" fullWidth />
              </CustomFieldLabel>
            </Grid>
            {isEdit ? (
              <StatusAware loading={loadingUpdate}>
                <Grid container>
                  <Grid item xs={4}>
                    <CustomFieldLabel source="enabled">
                      <BooleanInput source="enabled" fullWidth />
                    </CustomFieldLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFieldLabel source="one_off">
                      <BooleanInput source="one_off" fullWidth />
                    </CustomFieldLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomFieldLabel source="expires">
                      <DateTimeInput source="expires" fullWidth />
                    </CustomFieldLabel>
                  </Grid>
                </Grid>
              </StatusAware>
            ) : (
              <>
                <Grid item xs={4}>
                  <CustomFieldLabel source="enabled">
                    <BooleanField source="enabled" fullWidth />
                  </CustomFieldLabel>
                </Grid>
                <Grid item xs={4}>
                  <CustomFieldLabel source="one_off">
                    <BooleanField source="one_off" fullWidth />
                  </CustomFieldLabel>
                </Grid>
                <Grid item xs={4}>
                  <CustomFieldLabel source="expires">
                    <DateField source="expires" fullWidth />
                  </CustomFieldLabel>
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <CustomFieldLabel source="valorization_start_date">
                <DateField source="valorization_start_date" fullWidth />
              </CustomFieldLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomFieldLabel source="args">
                <TextField source="args" fullWidth />
              </CustomFieldLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomFieldLabel source="kwargs">
                <TextField source="kwargs" fullWidth />
              </CustomFieldLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomFieldLabel source="start_time">
                <DateField source="start_time" fullWidth />
              </CustomFieldLabel>
            </Grid>
            <Grid item xs={4}>
              <ConditionalPeriodicTypeField />
            </Grid>
          </Grid>
        </SimpleForm>
      </RecordContextProvider>
    </StatusAware>
  );
}
