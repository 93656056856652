import { Alert, AlertTitle } from "@mui/material";

type ErrorProps = {
  error: Error | string;
};

export const Error = ({ error }: ErrorProps) => {
  console.error(error);

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {typeof error === "string" ? (
        error
      ) : (
        <>
          <strong>{error.name}</strong> - {error.message}
          {String(error.cause ?? "")}
          {String(error.stack ?? "")}
        </>
      )}
    </Alert>
  );
};
