import { useCallback } from "react";
import { useRecordContext, useUpdate } from "react-admin";
import ConfigWebhookBaseForm from "./ConfigWebhookBaseForm";
import StatusAware from "shared/components/statuses/StatusAware";

type UpdateForm = {
  resource: string;
  webhookId: string;
};

export default function ConfigWebhookUpdateForm({ resource, webhookId }: UpdateForm) {
  const record = useRecordContext();
  const [update, { isLoading, error }]: any = useUpdate();
  const handleSubmit = useCallback(
    (data: any) => {
      update(resource, {
        id: webhookId,
        data,
        previousData: record,
      });
    },
    [record, resource, update, webhookId],
  );

  return (
    <StatusAware error={error} loading={isLoading}>
      <ConfigWebhookBaseForm handleSubmit={handleSubmit} />
    </StatusAware>
  );
}
