import { ChronicleFilenamesEnum, PlanningModeEnum } from "shared/enums/globalEnums";

export enum PlanningRoleEnum {
  socTrial1 = "socTrial1",
  socTrial2 = "socTrial2",
  socTrial3 = "socTrial3",
  pProgTrial1 = "pProgTrial1",
  pProgTrial2 = "pProgTrial2",
  pProgTrial3 = "pProgTrial3",
  pProgTrial4 = "pProgTrial4",
}

export enum PlanningTypeEnum {
  AFRR = "AFRR",
  SOC_MANAGEMENT = "SOC_MANAGEMENT",
}

export const baseAfrrValues = [
  {
    name: "SOC Management 1",
    mode: PlanningModeEnum.soc_management,
    chronicle_filename: null,
    duration: { hours: 1 },
    role: PlanningRoleEnum.socTrial1,
    type: PlanningTypeEnum.SOC_MANAGEMENT,
    testsParticipation: "test1",
  },
  {
    name: "AFRR 1",
    mode: PlanningModeEnum.afrr_rte,
    chronicle_filename: ChronicleFilenamesEnum.r2_essai_1_file1,
    duration: { minutes: 15 },
    role: PlanningRoleEnum.pProgTrial1,
    type: PlanningTypeEnum.AFRR,
    testsParticipation: "test1",
  },
  {
    name: "AFRR 1.1",
    mode: PlanningModeEnum.afrr_rte,
    chronicle_filename: ChronicleFilenamesEnum.r2_essai_1_file2,
    duration: { minutes: 20 },
    role: PlanningRoleEnum.pProgTrial2,
    type: PlanningTypeEnum.AFRR,
    testsParticipation: "test1",
  },
  {
    name: "SOC Management 2",
    mode: PlanningModeEnum.soc_management,
    chronicle_filename: null,
    duration: { minutes: 55 },
    role: PlanningRoleEnum.socTrial2,
    type: PlanningTypeEnum.SOC_MANAGEMENT,
    testsParticipation: "test2",
  },
  {
    name: "AFRR 2",
    mode: PlanningModeEnum.afrr_rte,
    chronicle_filename: ChronicleFilenamesEnum.r2_essai_2_file1,
    duration: { minutes: 15 },
    role: PlanningRoleEnum.pProgTrial3,
    type: PlanningTypeEnum.AFRR,
    testsParticipation: "test2",
  },
  {
    name: "AFRR 2.1",
    mode: PlanningModeEnum.afrr_rte,
    chronicle_filename: ChronicleFilenamesEnum.r2_essai_2_file2,
    duration: { minutes: 20 },
    role: PlanningRoleEnum.pProgTrial4,
    type: PlanningTypeEnum.AFRR,
    testsParticipation: "test2",
  },
  {
    name: "SOC Management 3",
    mode: PlanningModeEnum.soc_management,
    chronicle_filename: null,
    duration: { minutes: 55 },
    role: PlanningRoleEnum.socTrial3,
    type: PlanningTypeEnum.SOC_MANAGEMENT,
    testsParticipation: "all",
  },
];
