import { Box, Typography } from "@mui/material";
import { msalAccountName } from "app/providers/auth/authConfig";
import FormContainer from "shared/components/forms/FormContainer";
import { min } from "entities/maintenances/maintenanceHelpers";
import { validateMaintenanceEndDate, validatePlanningTime } from "shared/utils/validations";
import { baseResources } from "config_infos";
import { parseISO } from "date-fns";
import { maintenanceTypeChoices } from "shared/enums/reactAdminChoices";
import { parseErrorMessage } from "shared/utils/helpers";
import {
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";

export function MaintenancesEdit() {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (values: any) => {
    const { id, ...data } = values;
    const updatedData = {
      ...data,
      operator: msalAccountName,
    };
    update(
      baseResources.maintenance.MAINTENANCE,
      { data: updatedData, id },
      {
        onError: (error: any) => {
          notify(parseErrorMessage(error), {
            type: "error",
          });
        },
        onSuccess: () => {
          notify("Maintenance updated", {
            type: "success",
          });
          redirect(baseResources.maintenance.MAINTENANCE);
        },
      },
    );
  };
  return (
    <Edit>
      <SimpleForm mode="onBlur" onSubmit={handleSubmit}>
        <FormContainer>
          <SelectInput source="type" choices={maintenanceTypeChoices} />
          <FormDataConsumer>
            {({ formData }) => {
              const startBeforeToday = formData.start && parseISO(formData.start) <= new Date();
              return startBeforeToday ? (
                <Box display={"flex"} flexDirection={"column"}>
                  <Labeled source="start">
                    <DateField source="start" showTime />
                  </Labeled>
                  <Typography variant="caption" color={"primary"}>
                    Passed start dates cannot be changed
                  </Typography>
                </Box>
              ) : (
                <DateTimeInput
                  source="start"
                  validate={[validatePlanningTime]}
                  inputProps={{
                    min,
                  }}
                />
              );
            }}
          </FormDataConsumer>
          <DateTimeInput
            source="end"
            validate={[validatePlanningTime, validateMaintenanceEndDate]}
            inputProps={{
              min,
            }}
          />

          <ReferenceInput source="site_id" reference="sites" perPage={100} />
          {/* <TextInput source="operator" /> */}
          <TextInput source="comment" multiline />
        </FormContainer>
      </SimpleForm>
    </Edit>
  );
}
