import {
  optimizerCountryChoices,
  optimizerDirectionChoices,
  optimizerMarketChoices,
} from "entities/optimizer/opti_enums";
import { baseResources } from "config_infos";
import {
  AutocompleteInput,
  Create,
  DateInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";

export default function BidSettingsCreate() {
  return (
    <Create>
      <SimpleForm>
        <SelectInput source="market" label="Market" choices={optimizerMarketChoices} fullWidth />
        <SelectInput source="country" label="Country" choices={optimizerCountryChoices} fullWidth />
        <DateTimeInput source="start_date" label="Start Date" fullWidth />
        <DateTimeInput source="end_date" label="End Date" fullWidth />
        <DateInput source="delivery_date" label="Delivery Date" fullWidth />
        <ReferenceInput source="group_code" reference={baseResources.sites.GROUPS}>
          <AutocompleteInput
            optionText={({ id, name, code }: any) => {
              return `${id} - ${name} - ${code}`;
            }}
            fullWidth
          />
        </ReferenceInput>
        <NumberInput source="min_price" label="Minimum Price" fullWidth />
        <NumberInput source="max_price" label="Maximum Price" fullWidth />
        <NumberInput source="price" label="Price" fullWidth />
        <NumberInput source="min_quantity" label="Minimum Quantity" fullWidth />
        <NumberInput source="max_quantity" label="Maximum Quantity" fullWidth />
        <NumberInput source="offered_quantity" label="Offered Quantity" fullWidth />
        <SelectInput source="direction" label="Direction" choices={optimizerDirectionChoices} fullWidth />
      </SimpleForm>
    </Create>
  );
}
