import Admin from "./admin";
import "./App.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import fr from "date-fns/locale/fr";

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
    <Admin />
  </LocalizationProvider>
);

export default App;
