import { directionChoices } from "shared/enums/reactAdminChoices";
import { useMemo } from "react";
import { Datagrid, DateField, List, NumberField, NumberInput, SelectInput, TextField } from "react-admin";

const TopNiveauBidsList = () => {
  const filters = useMemo(
    () => [
      //  TODO: add ReferenceField once Backend updated
      //  <NumberInput label="ID" source="id" key="id" />
      <NumberInput label="Max Quantity" source="max_quantity" key="max_quantity" />,
      <NumberInput label="Min Quantity" source="min_quantity" key="min_quantity" />,
      <NumberInput label="Price" source="price" key="price" />,
      //  TODO: add ReferenceField once Backend updated
      //  <TextInput label="EDR" source="edr_id" key="edr_id" />,
      <SelectInput source="direction" choices={directionChoices} key="direction" />,
      <NumberInput label="Fat" source="fat" key="fat" />,
      //  TODO: add ReferenceField once Backend updated
      //  <NumberInput label="Gate" source="gate_id" key="gate_id" />
    ],
    [],
  );

  return (
    <List filters={filters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField source="created_at" locales={"fr-FR"} showTime />
        <DateField source="updated_at" locales={"fr-FR"} showTime />
        <NumberField source="max_quantity" />
        <NumberField source="min_quantity" />
        <NumberField source="price" />
        {/* TODO: add ReferenceField once Backend updated */}
        <TextField source="edr_id" label={"EDR code"} />
        <TextField source="direction" />
        <NumberField source="fat" />
        <TextField source="gate_id" />
        {/* TODO: add ReferenceField once Backend updated */}
        {/* <ReferenceField source="gate_id" reference={baseResources.topNiveau.GATES} /> */}
      </Datagrid>
    </List>
  );
};

export default TopNiveauBidsList;
