import { Divider } from "@mui/material";
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import React from "react";
import { menuItems } from "./MenuItems";

const RecursiveMenu = ({ items }: { items: any[] }) => {
  const renderMenuItems = (menuItems: any[]) =>
    menuItems.map((item: any) => {
      // Ensure path is valid: fallback to the first subItem's path if missing
      const path = item.path || (item.listItems?.[0]?.path ?? "");

      // Ensure name is provided, as it's required by MultiLevelMenu.Item
      if (!item.label) {
        console.error("Menu item is missing a label:", item);
        return null; // Skip rendering if the label is missing
      }

      const props = {
        to: path,
        icon: item.icon,
        name: item.label,
      };

      return (
        <React.Fragment key={item.label}>
          {item.listItems ? (
            <MultiLevelMenu.Item {...props}>
              {item.listItems && renderMenuItems(item.listItems)} {/* Render sub-items */}
            </MultiLevelMenu.Item>
          ) : (
            <MultiLevelMenu.Item {...props} />
          )}
          {item.top && <Divider />}
        </React.Fragment>
      );
    });

  return <>{renderMenuItems(items)}</>;
};

export const AppMenu = () => {
  return (
    <MultiLevelMenu initialOpen sx={{ width: 300 }} variant="default">
      <RecursiveMenu items={menuItems} />
    </MultiLevelMenu>
  );
};
