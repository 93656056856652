import { parseISO } from "date-fns";

import { ItemModeKeyType, ModeType, PlanningOrMaskType } from "shared/types";
import { PLANNING_MASK_MODE_ENUM, PLANNING_MODE_ENUM } from "shared/enums";

export const planningModeColors: ModeType = {
  soc_management: "#FAD2E1", // Pink Lavender
  fcr_rte_v2: "#FFDAC1", // Pale Pink
  fcr_rte_v2_no_reserve: "#E2F0CB", // Tea Green
  afrr_rte: "#B5EAD7", // Magic Mint
  afrr_fcr_rte: "#83C5BE", // Middle Blue Green
  power_chronicle: "#FF9AA2", // Melon
  power: "#FFB7B2", // Light Salmon Pink
  stop: "#FFDAC1", // Pale Pink
  fingrid_fcrn: "#006D77", // Cadet Blue
};

export const maskModeColors: ModeType = {
  // fcr_standalone: "#006D77", // Cadet Blue
  // r2_standalone: "#83C5BE", // Middle Blue Green
  r2_tests: "#EDF6F9", // Cultured
  r2_chronicles: "#FFDDD2", // Unbleached Silk
  r1_chronicles: "#E29578", // Burnt Sienna
  enspired: "#D62828", // Rose Vale
  r1_r2_mask: "#023E8A", // Royal Blue Dark
  technical_chronicle: "#0077B6", // Star Command Blue
  arbitrage_spot: "#9D0208", // Ruby Red
};

/**
 * Generic function to get color based on the mode.
 * @param mode The mode key which is a type of the enum.
 * @param colorMap The dictionary of colors corresponding to the mode enum.
 */
export const getColorByMode =
  (colorMap: ModeType) =>
  (mode: ItemModeKeyType): string => {
    return colorMap[mode] || "red"; // Default color is red if not found.
  };

/**
 * Generates chart datasets from a given array based on the specified mode.
 * @param items Array of planning or mask objects.
 * @param modeEnum Enumeration of modes.
 * @param getColorByMode Function to get the color based on the mode.
 * @param modeKey Key in the item object to compare for filtering.
 * @param yAxisLabel Label for the Y-axis in the chart.
 */
export function generateChartDatasets(
  items: PlanningOrMaskType[],
  modeEnum: typeof PLANNING_MODE_ENUM | typeof PLANNING_MASK_MODE_ENUM,
  getColorByMode: (mode: ItemModeKeyType) => string,
  modeKey: "operating_mode" | "mode",
  yAxisLabel: string,
) {
  return Object.keys(modeEnum).map((mode) => {
    const filteredItems = items.filter((item: PlanningOrMaskType) => item[modeKey] === mode);
    const data = filteredItems.map(({ overidable, start_date, end_date, ...rest }: PlanningOrMaskType) => ({
      x: [parseISO(start_date as string), parseISO(end_date as string)],
      y: yAxisLabel,
      overridable: yAxisLabel === "Plannings" && overidable,
      ...rest,
    }));
    const backgroundColor = getColorByMode(mode as keyof typeof modeEnum);

    return {
      label: mode,
      data,
      backgroundColor,
      barThickness: 60,
      borderRadius: 5,
      borderSkipped: false,
      borderColor: (context: any) => {
        if (!context?.raw?.overridable) return "red";
        return yAxisLabel === "Plannings" ? "black" : undefined;
      },
      borderWidth: yAxisLabel === "Plannings" ? 2 : undefined,
    };
  });
}
