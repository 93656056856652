import SearchIcon from "@mui/icons-material/Search";
import { Grid, Typography } from "@mui/material";
import { differenceInHours, formatDuration, intervalToDuration } from "date-fns";
import { useCallback } from "react";
import { DateTimeInput, FormDataConsumer, SaveButton, SimpleForm } from "react-admin";
import { DateRangeType } from "../../../types";

export type DateRangePickerProps = {
  setDateRange: (dateRange: any) => void;
  dateRange: DateRangeType;
  limitRangeInHours?: number;
  enforceMinMax?: boolean;
};

export type NullableDateRange = {
  startDate?: Date | string;
  endDate?: Date | string;
};

const DateRangePicker = ({
  setDateRange,
  dateRange,
  limitRangeInHours = 168,
  enforceMinMax = true,
}: DateRangePickerProps) => {
  const handleSubmit = useCallback(
    (values: NullableDateRange) => {
      setDateRange((preValues: DateRangeType) => ({
        ...preValues,
        ...values,
      }));
    },
    [setDateRange],
  );

  function getErrorMessage(hours: number): string {
    const duration = intervalToDuration({ start: 0, end: hours * 60 * 60 * 1000 });
    const formattedDuration = formatDuration(duration, { format: ["weeks", "days", "hours"] });

    return `Date range should be less than ${formattedDuration}`;
  }

  const dateWarning = limitRangeInHours ? getErrorMessage(limitRangeInHours) : "";

  const validateForm = useCallback(
    ({ startDate, endDate }: NullableDateRange) => {
      const errors: NullableDateRange = {
        startDate: "",
        endDate: "",
      };
      if (startDate && endDate) {
        if (startDate > endDate) {
          errors.startDate += "Start date should be before end date";
          errors.endDate += "End date should be after start date";
        }
        if (differenceInHours(endDate as Date, startDate as Date) > limitRangeInHours) {
          errors.startDate += getErrorMessage(limitRangeInHours);
          errors.endDate += getErrorMessage(limitRangeInHours);
        }
      }
      return {
        ...(errors.startDate ? { startDate: errors.startDate } : {}),
        ...(errors.endDate ? { endDate: errors.endDate } : {}),
      };
    },
    [limitRangeInHours],
  );

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      shouldUnregister
      sanitizeEmptyValues
      toolbar={false}
      mode="onBlur"
      reValidateMode="onBlur"
      validate={validateForm}
    >
      <Grid container justifyContent={"center"} alignItems={"center"} gap={2}>
        <Grid item>
          <Typography variant="h6" sx={{ mb: "20px" }}>
            Date Range :
          </Typography>
        </Grid>

        <Grid item>
          <FormDataConsumer>
            {({ formData }) => {
              const max = formData.endDate ? formData.endDate.toISOString().slice(0, 16) : null;
              return (
                <DateTimeInput
                  source="startDate"
                  defaultValue={dateRange.startDate}
                  helperText={dateWarning}
                  inputProps={
                    enforceMinMax
                      ? {
                          max,
                        }
                      : {}
                  }
                />
              );
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item>
          <FormDataConsumer>
            {({ formData }) => {
              const min = formData.startDate ? formData.startDate.toISOString().slice(0, 16) : null;
              return (
                <DateTimeInput
                  source="endDate"
                  defaultValue={dateRange.endDate}
                  helperText={dateWarning}
                  inputProps={
                    enforceMinMax
                      ? {
                          min,
                        }
                      : {}
                  }
                />
              );
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item>
          <SaveButton sx={{ mb: "20px" }} label="Search" icon={<SearchIcon />} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default DateRangePicker;
