import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import useBarChartPointsConfig from "features/charts/afrrEngagementChart/hooks/useBarChartPointsConfig";
import { Bar } from "react-chartjs-2";

// Register the necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale);

interface BarChartProps {
  data: ChartData<"bar">;
  onBarClick: (start_date: Date) => void;
  dateRange: { startDate: Date; endDate: Date };
}

const PointsBarChart = ({ data, onBarClick, dateRange }: BarChartProps) => {
  const { options } = useBarChartPointsConfig(data, dateRange, onBarClick);

  return <Bar data={data} options={options} />;
};

export default PointsBarChart;
