import { AfrrBidDirectionEnum, AfrrBidStatusEnum } from "entities/affr_engagement_service/types";
import { parseISO } from "date-fns";

export const prepareData = (points: any, dateRange: { startDate: Date; endDate: Date }) => {
  // Create an object to map points by status and direction
  const dataMapping: any = {};
  Object.values(AfrrBidStatusEnum).forEach((status: AfrrBidStatusEnum) => {
    dataMapping[`${status}_UPWARD`] = [];
    dataMapping[`${status}_DOWNWARD`] = [];
  });

  // Function to get the y value based on the point status and direction
  const getYValue = (point: any) => {
    // if the point is accepted or partially accepted, use the accepted quantity
    // else use the offered quantity
    const quantity =
      point.status === AfrrBidStatusEnum.ACCEPTED || point.status === AfrrBidStatusEnum.PARTIALLY_ACCEPTED
        ? point.accepted_quantity
        : point.offered_quantity;
    const yValue = +quantity;
    return point.direction === AfrrBidDirectionEnum.DOWNWARD ? -yValue : yValue;
  };

  const pointsByStatus = points.reduce((acc: any, point: any) => {
    // Parse the point dates and convert quantity and price fields to numbers
    const parsedPoint = {
      ...point,
      start_date: parseISO(point.start_date),
      end_date: parseISO(point.end_date),
      offered_quantity: +point.offered_quantity,
      offered_price: +point.offered_price,
      accepted_quantity: +point.accepted_quantity,
      accepted_price: +point.accepted_price,
      x: parseISO(point.start_date),
      y: getYValue(point),
    };

    const keyMapping = acc[`${point.status}_${point.direction}`];

    if (keyMapping) {
      acc[`${point.status}_${point.direction}`].push(parsedPoint);
    }

    return acc;
  }, dataMapping);

  // remove empty point arrays
  Object.keys(pointsByStatus).forEach((status) => {
    if (pointsByStatus[status].length === 0) {
      delete pointsByStatus[status];
    }
  });

  // Function to combine points
  const combinePoints = (points: any[]) => {
    const combinedPointsMap = new Map<string, any>();

    points.forEach((point) => {
      const key = `${point.start_date.toISOString()}_${point.end_date.toISOString()}_${point.direction}`;

      if (combinedPointsMap.has(key)) {
        const existingPoint = combinedPointsMap.get(key);
        existingPoint.offered_quantity += point.offered_quantity;
        existingPoint.offered_price += point.offered_price;
        existingPoint.accepted_quantity += point.accepted_quantity;
        existingPoint.accepted_price += point.accepted_price;
      } else {
        combinedPointsMap.set(key, { ...point });
      }
    });

    return Array.from(combinedPointsMap.values());
  };

  // Combine points in each status
  Object.keys(pointsByStatus).forEach((status) => {
    pointsByStatus[status] = combinePoints(pointsByStatus[status]);
  });

  // Function to get the color for each dataset
  const getDatasetColor = (status: AfrrBidStatusEnum) => {
    switch (status) {
      case AfrrBidStatusEnum.ACCEPTED:
        // Accepted bids: light green color
        return "rgba(11, 57, 134)";
      case AfrrBidStatusEnum.PARTIALLY_ACCEPTED:
        // Accepted bids: light green color
        return "rgba(11, 57, 134, 0.5)";
      case AfrrBidStatusEnum.REJECTED:
        // Rejected bids: dark red color
        return "rgba(244, 138, 171)";
      case AfrrBidStatusEnum.NOT_SENT:
        // Not sent bids: light orange color
        return "rgba(230, 138, 46)";
      case AfrrBidStatusEnum.PENDING:
        // Pending bids: light blue color
        return "rgba(11, 99, 134, 0.7)";
      case AfrrBidStatusEnum.REFUSED:
        // Refused bids: red color
        return "rgba(182, 23, 40";
      default:
        // Default color: black
        return "black";
    }
  };

  const datasets = Object.keys(pointsByStatus).map((status) => ({
    label: status,
    data: pointsByStatus[status],
    backgroundColor: getDatasetColor(status.replace(/_UPWARD|_DOWNWARD/g, "") as AfrrBidStatusEnum),
    // backgroundColor: getDatasetColor(status.split("_")[0] as AfrrBidStatusEnum),
    barPercentage: 1,
    categoryPercentage: 0.9,
  }));

  // create a function returning an array of all hours between dateRange.start and dateRange.end
  const getHoursBetweenDates = (dateRangeObj: { startDate: Date; endDate: Date }) => {
    const hours = [];
    for (let i = dateRangeObj.startDate.getTime(); i < dateRangeObj.endDate.getTime(); i += 60 * 60 * 1000) {
      hours.push(new Date(i));
    }
    return hours;
  };

  // Get all hours between the start and end dates
  const labels = getHoursBetweenDates(dateRange);

  return { labels, datasets }; // Return the prepared labels and datasets
};
