import axios from "axios";
import { baseResources } from "config_infos";
import { format } from "date-fns";
import { arrayToChoices } from "shared/utils/reactAdminHelpers";
import { useCallback, useEffect, useState } from "react";
import {
  AutocompleteInput,
  Button,
  Identifier,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  useNotify,
} from "react-admin";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { ChoicesType, ChronicleTypeEnum, GenerationTabFormProps } from "../types";

const TriggerDagForm = ({ initialFormValues, resetForm }: GenerationTabFormProps) => {
  const isPerformance = initialFormValues.chronicleType === ChronicleTypeEnum.Performance;
  const notify = useNotify();
  const [choices, setchoices] = useState<{ type: ChoicesType; chronicleFilename: ChoicesType; lot: ChoicesType }>({
    type: [],
    chronicleFilename: [],
    lot: [],
  });

  const BASE_URL = `${process.env.REACT_APP_AIRFLOW_SERVER}/api/v1/dags/`;

  const getEnum = useCallback((element: { schema: { enum: any[] } }): Identifier[] => element?.schema?.enum || [], []);

  const getGenerationDetails = useCallback(async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: `${BASE_URL}chronicle_report_generation_${initialFormValues.chronicleType}_JBox_${initialFormValues.jboxType}/details`,
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_AIRFLOW_API_KEY}`,
        },
        withCredentials: true,
      });
      if (data && data.params) {
        const { type, chronicle_filename, lot } = data.params;
        setchoices({
          type: arrayToChoices(getEnum(type)),
          chronicleFilename: arrayToChoices(getEnum(chronicle_filename)),
          lot: arrayToChoices(getEnum(lot)),
        });
      }
    } catch (error) {
      console.error("error:", error);
    }
  }, [getEnum, initialFormValues.chronicleType, initialFormValues.jboxType, BASE_URL]);

  useEffect(() => {
    getGenerationDetails();
  }, [getGenerationDetails]);

  const handleSubmit: SubmitHandler<FieldValues> = async (values) => {
    if (!values.site_id) {
      notify("Please select a site", { type: "error" });
    } else {
      const now = new Date();
      const logical_date = now.toISOString();
      const postUrlId = "trigger_chronicle_report_generation";
      const dag_run_id = `${postUrlId}_${values.site_id}_${format(now, "yyyyMMddHHmmss")}`;
      const note = "Softval Trigger";
      const payload = { conf: values, dag_run_id, logical_date, note };
      const hiddenInfos = {
        prod: process.env.REACT_APP_ENVIRONNEMENT === "production",
        chronicle_type: initialFormValues.chronicleType,
      };
      payload.conf = { ...payload.conf, ...hiddenInfos };

      try {
        const { status } = await axios({
          method: "post",
          url: `${BASE_URL}${postUrlId}/dagRuns`,
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_AIRFLOW_API_KEY}`,
            ContentType: "application/json",
            Accept: "application/json",
          },
          data: payload,
        });
        if (status >= 200 && status < 300) {
          notify("Succefully started chronicle generation", { type: "success" });
        }
      } catch (error) {
        notify("There was a problem starting chronicle generation", { type: "error" });
        console.error("error:", error);
      }
    }
  };

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      title="Performance Generation"
      toolbar={
        <>
          <SaveButton label="generate report" />
          <Button onClick={resetForm} label="reset " variant="contained" size="medium" sx={{ ml: 2 }} />
        </>
      }
      mode="onBlur"
    >
      <ReferenceInput source="site_id" reference={baseResources.sites.SITES}>
        <AutocompleteInput
          optionText={({ id, name }: any) => {
            return `${id} - ${name}`;
          }}
          validate={[required()]}
          fullWidth
        />
      </ReferenceInput>
      <SelectInput source="type" choices={choices.type} validate={[required()]} fullWidth />
      {isPerformance && <SelectInput source="lot" choices={choices.lot} validate={[required()]} fullWidth />}
      <SelectInput source="chronicle_filename" choices={choices.chronicleFilename} validate={[required()]} fullWidth />
    </SimpleForm>
  );
};

export default TriggerDagForm;
