import { useEffect, useMemo, useState } from "react";
import { FormDataConsumer, RadioButtonGroupInput, required, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import { CustomRadioInputProps, InitialFormValuesProps, JboxTypeEnum } from "../types";
import TriggerDagForm from "./TriggerDagForm";
import { enumToChoices } from "shared/utils/reactAdminHelpers";

const CustomToolbar = ({ disabled }: { disabled: boolean }) => {
  return (
    <Toolbar>
      <SaveButton label="load form" disabled={disabled} />
    </Toolbar>
  );
};

export default function GenerationInitialForm() {
  const [initalFormValues, setInitalFormValues] = useState<InitialFormValuesProps | null>(null);
  const [disabled, setDisabled] = useState(false);
  const handleSubmit: SubmitHandler<FieldValues> = (values) => {
    setInitalFormValues(values as any);
    setDisabled(true);
  };
  const resetForm = () => {
    setInitalFormValues(null);
    setDisabled(false);
  };

  const jboxTypeChoices = useMemo(() => enumToChoices(JboxTypeEnum), []);

  return (
    <>
      <SimpleForm toolbar={<CustomToolbar disabled={disabled} />} onSubmit={handleSubmit}>
        <RadioButtonGroupInput
          source="jboxType"
          parse={(value) => +value}
          validate={required()}
          disabled={disabled}
          choices={jboxTypeChoices}
        />

        <FormDataConsumer>
          {({ formData }) => {
            return <CustomRadioInput formData={formData} disabled={disabled} />;
          }}
        </FormDataConsumer>
      </SimpleForm>
      {initalFormValues && <TriggerDagForm initialFormValues={initalFormValues} resetForm={resetForm} />}
    </>
  );
}

const CustomRadioInput = ({ formData, disabled }: CustomRadioInputProps) => {
  const { setValue } = useFormContext();
  const inputDisabled = disabled || !formData.jboxType;
  const isJbox1 = formData.jboxType === 1;
  const choices = [{ id: "MSI", name: "MSI" }];
  if (isJbox1) {
    choices.push({ id: "Performance", name: "Performance" });
  }
  useEffect(() => {
    if (formData.jboxType === 2) {
      setValue("chronicleType", "MSI");
    }
  }, [setValue, formData.jboxType]);
  return (
    <RadioButtonGroupInput disabled={inputDisabled} source="chronicleType" validate={required()} choices={choices} />
  );
};
