import { baseResources } from "config_infos";
import { directionChoices, afrrPointStatusChoices } from "shared/enums/reactAdminChoices";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin";

import { useCallback, useMemo } from "react";

const AfrrEngagementPointList = () => {
  const getBTSOptionText = useCallback(
    (record: RaRecord) => `${record.mrid} ${record.direction} ${record.divisible} ${record.symetrical_feature}`,
    [],
  );

  const filters = useMemo(
    () => [
      <DateInput label="Start Date" source="start_date" key="start_date" />,
      <DateInput label="Start Date (From)" source="start_date__gte" key="start_date__gte" />,
      <DateInput label="Start Date (To)" source="start_date__lte" key="start_date__lte" />,
      <DateInput label="End Date" source="end_date" key="end_date" />,
      <DateInput label="End Date (From)" source="end_date__gte" key="end_date__gte" />,
      <DateInput label="End Date (To)" source="end_date__lte" key="end_date__lte" />,
      <NumberInput label="Position" source="position" key="position" />,
      <SelectInput label="Direction" source="direction" key="direction" choices={directionChoices} />,
      <SelectInput label="Status" source="status" key="status" choices={afrrPointStatusChoices} />,
      <NumberInput
        label="Accepted Quantity (Greater than)"
        source="accepted_quantity__gt"
        key="accepted_quantity__gt"
      />,
      <NumberInput label="Accepted Quantity (Less than)" source="accepted_quantity__lt" key="accepted_quantity__lt" />,
      <ReferenceInput
        label="Bid Time Series ID"
        source="bid_time_series_id"
        key="bid_time_series_id"
        reference={baseResources.afrrEngagement.BID_TIME_SERIES}
      >
        <AutocompleteInput optionText={getBTSOptionText} sx={{ width: 400 }} />
      </ReferenceInput>,
    ],
    [getBTSOptionText],
  );

  return (
    <List filters={filters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField showTime locales={"fr-FR"} source="created_at" />
        <DateField showTime locales={"fr-FR"} source="updated_at" />
        <DateField showTime locales={"fr-FR"} source="start_date" />
        <DateField showTime locales={"fr-FR"} source="end_date" />
        <ReferenceField
          source="bid_time_series_id"
          reference={baseResources.afrrEngagement.BID_TIME_SERIES}
          link="show"
        >
          <FunctionField render={getBTSOptionText} />
        </ReferenceField>
        <NumberField source="position" />
        <NumberField source="offered_quantity" />
        <NumberField source="offered_price" />
        <TextField source="direction" />
        <NumberField source="accepted_quantity" />
        <TextField source="accepted_price" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};

export default AfrrEngagementPointList;
